import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import ChipArray from './fields/ChipArray'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    width: 300,
  },
  name: {
    width: 200,
    display: '-webkit-box',
  },
})

export const RoomGroupsList = ({ ...props }) => {
  const classes = useStyles()
  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title='Room Groups'
      perPage={15}
      bulkActionButtons={false}
    >
      <Datagrid rowClick='edit'>
        <TextField className={classes.name} source='name' />
        <TextField className={classes.description} source='description' />
        <ChipArray source='meetingRooms' />
      </Datagrid>
    </List>
  )
}

RoomGroupsList.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default RoomGroupsList
