import React from 'react'
import BookingList from './BookingList'
import BookingIcon from '@material-ui/icons/RoomService'

const list = (props) => <BookingList {...props} />
const icon = () => <BookingIcon />

const Booking = {
  list,
  icon,
}

export default Booking
