// component for displaying and resolving conflicting bookings
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
} from '@material-ui/core'
import ConflictingEventOptions from './ConflictingEventOptions'
import dataProvider from '../../dataProvider'
import { GET_LIST } from 'react-admin'

const styles = () => ({
  conflictList: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
})

const ConflictForm = ({
  pendingBookingConflict,
  onAllCoflictsResolved,
  resolvingAllConficts,
  classes,
}) => {
  // the list of rooms, regardless of their availability
  // this is to provide the conflict options component the name <-> room id mapping
  const [roomList, setRoomList] = React.useState([])

  const [resolvedBookings, setResolvedBookings] = React.useState([])

  // marking whether another booking event is submitted. This time all conflicts will be ignored
  const [submittingBooking, setSubmittingBooking] = React.useState(false)

  const resolveIndividualBooking = (i) => {
    const newSetOfResolvedBookings = [...resolvedBookings, i]
    if (
      newSetOfResolvedBookings.length ===
      pendingBookingConflict?.impossibleBookings.length
    ) {
      onAllCoflictsResolved()
    }
    setResolvedBookings(newSetOfResolvedBookings)
  }

  const resolveAllConflicts = async () => {
    setSubmittingBooking(true)
    await onAllCoflictsResolved()
    setSubmittingBooking(false)
  }
  // when the component first load, also load the list of rooms...
  React.useEffect(() => {
    const fetchRooms = async () => {
      const { data } = await dataProvider(GET_LIST, 'meeting-rooms')
      setRoomList(data)
    }

    fetchRooms()
  }, [])

  return (
    <Dialog open={!!pendingBookingConflict} maxWidth='xl' fullWidth>
      <DialogTitle>There are conflicts in booking</DialogTitle>
      <DialogContent>
        <div className={classes.conflictList}>
          {pendingBookingConflict?.impossibleBookings.map(
            (impossibleBooking, i) => (
              <ConflictingEventOptions
                roomList={roomList}
                booking={pendingBookingConflict.booking}
                index={i + 1}
                key={i}
                impossibleBooking={impossibleBooking}
                onBookingResolved={resolveIndividualBooking}
              />
            ),
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {submittingBooking || resolvingAllConficts ? (
          <CircularProgress />
        ) : (
          <Button onClick={resolveAllConflicts}>
            Resolve all conflicts (cancel all unresolved bookings)
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
ConflictForm.propTypes = {
  pendingBookingConflict: PropTypes.objectOf({
    booking: PropTypes.object.isRequired,
    impossibleBookings: PropTypes.arrayOf(
      PropTypes.objectOf({
        // not sure if they are string or date, so just put `any` here.
        // Moment will figure it out in <ConflictingEventOptions />
        startTime: PropTypes.any.isRequired,
        endTime: PropTypes.any.isRequired,
        // the number used to retrieve back the name of the room intended to book
        roomId: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
  resolvingAllConficts: PropTypes.bool.isRequired,
  onAllCoflictsResolved: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(ConflictForm)
