import React from 'react'
import { Admin, Resource, Layout } from 'react-admin'
import '../common/fonts'
import '../common/styles/globalStyles.css'
import theme from '../theme'
import dataProvider from '../dataProvider'
import Users from '../users'
import Community from '../community'
import CommunityUpdates from '../community/updates'
import Booking from '../booking'
import AreaAppBar from '../common/AreaAppBar'
import customRoutes from '../common/customRoutes'
import authProvider from '../authProvider'
import LoginPage from './LoginPage'
import Reports from '../reports'
import Rooms from '../rooms'
import RoomGroups from '../roomGroups'
import Messages from '../messages'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const AreaLayout = (props) => <Layout {...props} appBar={AreaAppBar} />

const AdminPage = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Admin
      loginPage={LoginPage}
      authProvider={authProvider}
      customRoutes={customRoutes}
      layout={AreaLayout}
      theme={theme}
      dataProvider={dataProvider}
    >
      {(permissions) => {
        if (permissions === 'admin') {
          return [
            <Resource
              key={1}
              name='person/all'
              options={{ label: 'Users' }}
              {...Users}
            />,
            <Resource key={2} name='person' />,
            <Resource
              key={4}
              name='communityUpdates'
              options={{ label: 'Community Updates', parent: 'communities' }}
              {...CommunityUpdates}
            />,
            <Resource
              key={5}
              name='communities'
              options={{ label: 'Communities' }}
              {...Community}
            />,
            <Resource
              key={6}
              name='bookings'
              options={{ label: 'Bookings' }}
              {...Booking}
            />,
            // <Resource key={6} name='news' {...News} />,

            <Resource
              key={7}
              name='meeting-rooms'
              options={{ label: 'Rooms' }}
              {...Rooms}
            />,
            <Resource
              key={8}
              name='group-rooms'
              options={{ label: 'Room Groups' }}
              {...RoomGroups}
            />,
            <Resource
              key={9}
              name='active-message'
              options={{ label: 'TV CMS' }}
              {...Messages}
            />,
            <Resource
              key={10}
              name='report'
              options={{ label: 'Reports' }}
              {...Reports}
            />,
            // <Resource
            //   key={11}
            //   options={{ label: 'Labels' }}
            //   name='persons/labels'
            //   {...Labels}
            // />,
          ]
        } else if (permissions === 'staff') {
          return [
            <Resource key={1} name='person' />,
            <Resource
              key={2}
              name='communities'
              options={{ label: 'Communities' }}
              {...Community}
            />,
            <Resource
              key={4}
              name='communityUpdates'
              options={{ label: 'Community Updates', parent: 'communities' }}
              {...CommunityUpdates}
            />,
          ]
        } else {
          return [<Resource key={1} name='person' />]
        }
      }}
    </Admin>
  </MuiPickersUtilsProvider>
)

export default AdminPage
