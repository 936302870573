import React from 'react'
import { Datagrid, List, TextField, DateField } from 'react-admin'
import MessageDeleteButton from './MessageDeleteButton'
import MessageActiveStatus from './MessageActiveStatus'

export const MessageList = (props) => {
  return (
    <List
      {...props}
      title='Messages'
      sort={{ field: 'expiry', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid
        rowClick={(_, __, { isActive }) => isActive && 'edit'}
        isRowSelectable={() => false}
      >
        <TextField source='message' />
        <DateField source='expiry' locales='en-US' showTime />
        <MessageActiveStatus label='Active' />
        <MessageDeleteButton />
      </Datagrid>
    </List>
  )
}

export default MessageList
