import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const RoomGroupCreate = (props) => (
  <Create {...props}>
    <SimpleForm variant='standard' margin='normal'>
      <TextInput source='name' />
      <TextInput source='description' multiline />
    </SimpleForm>
  </Create>
)

export default RoomGroupCreate
