import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

const SelectOptionField = ({
  name,
  label,
  value,
  options,
  onChange,
  multiple,
  className,
  fullWidth = true,
}) => (
  <FormControl fullWidth={fullWidth} className={className || ''}>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Select multiple={multiple} name={name} value={value} onChange={onChange}>
      {Object.entries(options).map(([optionKey, optionValue]) => (
        <MenuItem key={optionKey} value={optionKey}>
          {optionValue}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

SelectOptionField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
}

export default SelectOptionField
