import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  images: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContainer: {
    margin: '0 20px 20px 0',
  },
  image: {
    maxWidth: '300px',
    maxHeight: '300px',
  },
  fileName: {
    marginRight: theme.spacing(2),
  },
})

const AttachmentImages = ({ onDelete, images = [], classes }) => {
  return (
    <>
      <Typography className={classes.header}>Image Attachments:</Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <div key={image.id} className={classes.imageContainer}>
            <div>
              <span className={classes.fileName}>{image.fileName}</span>
              <Button onClick={() => onDelete(image.id)} color='primary'>
                Delete
              </Button>
            </div>
            <a href={image.url} target='_blank' rel='noopener noreferrer'>
              <img className={classes.image} src={image.url} alt={image.url} />
            </a>
          </div>
        ))}
      </div>
      {!images.length && (
        <Typography variant='body2'>No images were added yet</Typography>
      )}
    </>
  )
}

AttachmentImages.propTypes = {
  images: PropTypes.array,
  onDelete: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(styles)(AttachmentImages)
