import React from 'react'
import ReportIcon from '@material-ui/icons/Report'
import ReportList from './ReportsList'

const list = (props) => <ReportList {...props} />
const icon = () => <ReportIcon />

const Reports = {
  list,
  icon,
}

export default Reports
