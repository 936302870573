export const GET_COMMUNITY_EVENTS = 'GET_COMMUNITY_EVENTS'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_URL = 'UPLOAD_IMAGE_URL'
export const ARCHIVE_DELETE = 'ARCHIVE_DELETE'
export const PUT_UPDATE = 'PUT_UPDATE'
export const VERIFY_COMMUNITY = 'VERIFY_COMMUNITY'
export const UNVERIFY_USER = 'UNVERIFY_USER'
export const ASSIGN_LABELS = 'ASSIGN_LABELS'
export const DEASSIGN_LABELS = 'DEASSIGN_LABELS'
export const DELETE_LABELS = 'DELETE_LABELS'
