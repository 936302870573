import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  withStyles,
} from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'

import RecurrenceField from './RecurrenceField'
import ParticipantSelect from './ParticipantsSelect'
import useBookingAvailability from '../useBookingAvailability'
import MeetingPurpose from './MeetingPurpose'

const styles = () => ({
  textField: {
    marginBottom: '1rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
})

export const meetingPurposeOptions = {
  meeting: 'Meeting',
  event: 'Event',
}

const BookingForm = ({
  openDialog,
  selectedStartTime,
  selectedEndTime,
  onClose,
  user,
  classes,
  onCreateBookRoom,
}) => {
  const [subject, setSubject] = useState('')
  const [roomIds, setRoomIds] = useState([])
  const [meetingPurpose, setMeetingPurpose] = useState('meeting')
  const [participants, setParticipants] = useState([])
  const [submittingBooking, setSubmittingBooking] = useState(false)
  const [recurrentOptions, setRecurrentOptions] = useState(null)
  const [startTime, setStartTime] = useState(selectedStartTime)
  const [endTime, setEndTime] = useState(selectedEndTime)
  const [error, setError] = useState(false)

  const [availableRooms] = useBookingAvailability({ startTime, endTime })
  const { email, personId } = user

  useEffect(() => {
    setStartTime(selectedStartTime)
  }, [selectedStartTime])

  useEffect(() => {
    setEndTime(selectedEndTime)
  }, [selectedEndTime])

  const validateForm = () => {
    if (roomIds.length === 0) return false
    if (
      // there are recurrence, and its chosen as the number of occurrences
      recurrentOptions?.endRecurrenceType === 'occurrences' &&
      // but then the recurring times is <= 0...
      recurrentOptions?.endRecurrenceValue <= 0
    )
      return false
    return email && personId && subject && startTime && endTime
  }

  async function handleCreateBookRoom() {
    setError(false)
    if (!validateForm()) {
      setError(true)
    } else {
      const recurOpts =
        recurrentOptions === null
          ? // no recurring options means it is a single event
            { type: 'single' }
          : // otherwise it is recurring, spread the options
            {
              type: 'recurring',
              frequency: recurrentOptions.frequency,
              [recurrentOptions.endRecurrenceType === 'occurrences'
                ? 'occurrences'
                : 'lastBookingDate']: recurrentOptions.endRecurrenceValue,
            }
      setSubmittingBooking(true)
      await onCreateBookRoom({
        bookedBy: personId,
        bookerEmail: email,
        subject,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        roomIds,
        purpose: meetingPurpose,
        participants,
        ...recurOpts,
      })
      setSubmittingBooking(false)
    }
  }

  const onSubjectChange = (e) => {
    setSubject(e.target.value)
  }

  const onRoomIdChange = (e) => {
    setRoomIds(e.target.value)
  }

  const onMeetingPurposeChange = (e) => {
    setMeetingPurpose(e.target.value)
  }

  const onRecurrentOptionsChange = React.useCallback(
    (opt) => {
      setRecurrentOptions(opt)
    },
    [setRecurrentOptions],
  )

  return (
    <Dialog open={openDialog} onClose={onClose} fullWidth>
      <DialogTitle>Create Booking</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            id='subject'
            className={classes.textField}
            autoFocus
            name='subject'
            label='Subject'
            type='text'
            value={subject}
            onChange={onSubjectChange}
            required
            fullWidth
            error={error}
          />
          <TimePicker
            ampm={false}
            maxDate={endTime}
            className={classes.textField}
            minutesStep={5}
            name='startTime'
            label='Start Time'
            value={startTime}
            onChange={setStartTime}
            required
            error={error}
            fullWidth
            autoOk
          />
          <TimePicker
            ampm={false}
            minutesStep={5}
            minDate={startTime}
            className={classes.textField}
            name='endTime'
            label='End Time'
            value={endTime}
            onChange={setEndTime}
            required
            error={error}
            fullWidth
            autoOk
          />
          <FormControl fullWidth>
            <InputLabel htmlFor='roomIds'>Room</InputLabel>
            <Select
              name='roomIds'
              value={roomIds}
              className={classes.textField}
              onChange={onRoomIdChange}
              multiple
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          availableRooms.find(
                            (room) =>
                              room.meetingRoomId.toString() ===
                              value.toString(),
                          )?.name ?? ''
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )
              }}
            >
              {availableRooms.map((room) => (
                <MenuItem key={room.meetingRoomId} value={room.meetingRoomId}>
                  {room.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MeetingPurpose
            className={classes.textField}
            value={meetingPurpose}
            onPurposeChange={onMeetingPurposeChange}
          />
          <ParticipantSelect
            participants={participants}
            onParticipantsChange={setParticipants}
          />
          <RecurrenceField onRecurringOptionChange={onRecurrentOptionsChange} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' disabled={submittingBooking}>
          Cancel
        </Button>
        {submittingBooking ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={!validateForm()}
            variant='contained'
            color='primary'
            onClick={handleCreateBookRoom}
          >
            Book Room
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

BookingForm.propTypes = {
  openDialog: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  selectedStartTime: PropTypes.object,
  selectedEndTime: PropTypes.object,
  roomIds: PropTypes.array,
  classes: PropTypes.object,
  onCreateBookRoom: PropTypes.func.isRequired,
}

export default withStyles(styles)(BookingForm)
