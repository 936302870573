import { useCallback, useState } from 'react'
import { CREATE, GET_LIST, GET_ONE, DELETE } from 'ra-core'
import moment from 'moment-timezone'
import { useNotify } from 'react-admin'
import isEmpty from 'lodash/isEmpty'
import dataProvider from '../dataProvider'
import {
  deleteBooking as deleteBookingService,
  updateBooking as updateBookingService,
  changeRoomOfBooking,
  updateAutoCancelStatus,
} from './bookingService'
import _ from 'lodash'

const useBookings = ({ shouldConvert, roomIds, startTime, endTime } = {}) => {
  const [bookings, setBookings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const notify = useNotify()

  const fetchBookings = useCallback(async () => {
    if (!roomIds || !roomIds.length) {
      return
    }
    setIsLoading(true)
    try {
      const response = await dataProvider(
        GET_LIST,
        `meeting-rooms/bookings?roomIds=${roomIds.join(
          ',',
        )}&from=${startTime}&to=${endTime}`,
      )
      let bookings
      if (shouldConvert) {
        /* This is due to a bug. If event's endDate is the day after, it counts as a full day event.
            This subtract 1ms from the events finishing at midnight */
        bookings = response.data.map(removeOneMsFromBookingsFinishingMidnight)
      } else {
        bookings = response.data
      }
      setBookings(bookings)
    } catch (error) {
      notify('Failed loading bookings!', 'error')
    } finally {
      setIsLoading(false)
    }
  }, [
    shouldConvert,
    setBookings,
    setIsLoading,
    notify,
    roomIds,
    startTime,
    endTime,
  ])

  async function fetchOneBooking(bookingId) {
    try {
      setIsLoading(true)
      const response = await dataProvider(GET_ONE, `room-bookings/`, {
        id: bookingId,
      })
      return response.data
    } catch (error) {
      notify('Failed loading booking', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Participants functions
   */
  const isEmail = (idOrEmail) => idOrEmail.toString().includes('@')
  async function addParticipants(bookingId, participants) {
    // nothing to add
    if (participants?.length === 0) return

    return await dataProvider(
      CREATE,
      `room-bookings/${bookingId}/participants`,
      {
        data: {
          personIds: participants.filter((p) => !isEmail(p)),
          emails: participants.filter((p) => isEmail(p)),
        },
      },
    )
  }

  async function removeParticipants(bookingId, participants) {
    // nothing to remove
    if (participants?.length === 0) return

    return await dataProvider(
      DELETE,
      `room-bookings/${bookingId}/participants`,
      {
        data: {
          personIds: participants.filter((p) => !isEmail(p)),
          emails: participants.filter((p) => isEmail(p)),
        },
      },
    )
  }

  async function deleteBooking(
    { bookingId },
    isDeletingRecurrentEvent = false,
  ) {
    try {
      await deleteBookingService(bookingId, isDeletingRecurrentEvent)
      // Update local bookings after delete
      if (!isDeletingRecurrentEvent) {
        setBookings(
          bookings.filter((booking) => booking.bookingId !== bookingId),
        )
      } else {
        // need to refetch all bookings
        fetchBookings()
      }
      notify('Booking Deleted!')
    } catch (error) {
      notify('Booking could not be deleted', 'error')
    }
  }
  async function updateBooking({
    event,
    subject,
    roomId,
    start,
    end,
    purpose,
    participants = [],
    isAutoCancelEnabled,
  }) {
    const subjectPurposeChanges = {
      subject: subjectChanged(subject) ? subject : undefined,
      purpose: event.purpose !== purpose ? purpose : undefined,
    }

    const getTimeChanges = () => {
      if (start && end) {
        const currentStartTime = moment(event.startTime).toISOString()
        const currentEndTime = moment(event.endTime).toISOString()
        if (
          (start.toISOString() !== currentStartTime ||
            end.toISOString() !== currentEndTime) &&
          moment(end).isAfter(moment(start))
        ) {
          if (isRoomAlreadyBooked(start, end)) {
            notify('Room already booked at selected time', 'error')
          } else {
            return {
              startTime: start.toISOString(),
              endTime: end.toISOString(),
            }
          }
        }
      }
      return {}
    }

    const changes = {
      ...subjectPurposeChanges,
      ...getTimeChanges(),
    }

    const promises = []
    if (Object.entries(changes).length > 0) {
      promises.push(updateBookingService(event.bookingId, changes))
    }
    if (roomId && event.roomId !== roomId) {
      promises.push(changeRoomOfBooking(event.bookingId, roomId))
    }
    if (event.isAutoCancelEnabled !== isAutoCancelEnabled) {
      promises.push(
        updateAutoCancelStatus(event.bookingId, isAutoCancelEnabled),
      )
    }

    if (promises.length > 0) {
      const prevBooking = updateLocalBookings(event.bookingId, changes)
      try {
        await Promise.all(promises)
        fetchBookings()
        notify('Successfully adjusted booking!')
      } catch (error) {
        restoreLocalBooking(prevBooking)
        notify('Failed', 'error')
        return // no futher actions taken
      }
    }

    // additionally, if there are changes in participants list. remove all existings and add the new participants
    if (!_.isEqual(event.participants?.sort() || [], participants.sort())) {
      await removeParticipants(event.bookingId, event.participants || [])
      await addParticipants(event.bookingId, participants)
      notify('Successfully updated participants list!')
    }

    // Helpers
    function subjectChanged(subject) {
      return !isEmpty(subject) && subject !== event.subject
    }

    function isRoomAlreadyBooked(startTime, endTime) {
      // Add one millisecond to avoid conflicts with other booking ending time.
      const newStartTime = moment(startTime).add(1, 'milliseconds')
      // Subtract one millisecond to avoid conflicts with other booking starting time.
      const newEndTime = moment(endTime).subtract(1, 'milliseconds')

      return bookings.some((booking) => {
        const bookingStartTime = moment.isMoment(booking.startTime)
          ? booking.startTime
          : moment(booking.startTime)
        const bookingEndTime = moment.isMoment(booking.endTime)
          ? booking.endTime
          : moment(booking.endTime)

        const isBooked =
          booking.bookingId !== event.bookingId &&
          booking.roomId === event.roomId &&
          (bookingStartTime.isBetween(newStartTime, newEndTime) ||
            bookingEndTime.isBetween(newStartTime, newEndTime) ||
            newStartTime.isBetween(bookingStartTime, bookingEndTime) ||
            newEndTime.isBetween(bookingStartTime, bookingEndTime))

        return isBooked
      })
    }
    function updateLocalBookings(bookingId, changes) {
      let prevBooking
      setBookings(
        bookings.map((booking) => {
          if (booking.bookingId === bookingId) {
            prevBooking = booking
            return removeOneMsFromBookingsFinishingMidnight({
              ...booking,
              ...changes,
            })
          }
          return booking
        }),
      )
      return prevBooking
    }
    function restoreLocalBooking(prevBooking) {
      setBookings(
        bookings.map((booking) =>
          booking.bookingId === prevBooking.bookingId ? prevBooking : booking,
        ),
      )
    }
  }
  async function createBooking(booking, ignoreConflictingBookings = false) {
    try {
      const response = await dataProvider(
        CREATE,
        `book/book-room${
          ignoreConflictingBookings ? '?ignoreConflictingBookings=true' : ''
        }`,
        {
          data: {
            ...booking,
            participantIds: booking.participants.filter((p) => !isEmail(p)),
            participantEmails: booking.participants.filter((p) => isEmail(p)),
          },
        },
      )

      setBookings(
        bookings.concat(
          removeOneMsFromBookingsFinishingMidnight(response.data),
        ),
      )

      // refresh the list of bookings
      fetchBookings()
    } catch (err) {
      if (err.response) {
        if (err.response.status === 422) {
          notify('Room is not available.', 'error')
        } else if (err.response.data?.extraInfo?.impossibleBookings) {
          // return the impossible list of bookings in case there are any
          return err.response.data?.extraInfo?.impossibleBookings
        } else {
          notify('Error booking room.', 'error')
        }
      }
    }
  }
  async function updateAutoCancel(bookingId, status) {
    try {
      await updateAutoCancelStatus(bookingId, status)
      fetchBookings()
    } catch (err) {
      if (err.response) {
        notify('Error updating auto-cancel status.', 'error')
      }
    }
  }

  return {
    bookings,
    isLoading,
    fetchBookings,
    fetchOneBooking,
    deleteBooking,
    updateBooking,
    createBooking,
    updateAutoCancel,
  }
}

const removeOneMsFromBookingsFinishingMidnight = (booking) => {
  const startTime = moment(booking.startTime)
  const endTime = moment(booking.endTime).isAfter(
    moment(booking.startTime).endOf('d'),
  )
    ? moment(booking.endTime).subtract(1, 'ms')
    : moment(booking.endTime)
  return {
    ...booking,
    startTime: startTime.toDate(),
    endTime: endTime.toDate(),
  }
}

export default useBookings
