import React from 'react'
import MeetingRoomIcon from '@material-ui/icons/Dns'
import RoomGroupsList from './RoomGroupsList'
import RoomGroupEdit from './RoomGroupEdit'
import RoomGroupCreate from './RoomGroupCreate'

const list = (props) => <RoomGroupsList {...props} />
const edit = (props) => <RoomGroupEdit {...props} />
const create = (props) => <RoomGroupCreate {...props} />
const icon = () => <MeetingRoomIcon />

const RoomGroups = {
  list,
  edit,
  create,
  icon,
}

export default RoomGroups
