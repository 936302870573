import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import {
  withStyles,
  IconButton,
  Typography,
  ButtonGroup,
  Button,
} from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  buttons: {
    marginLeft: 'auto',
  },
})

const CalendarToolbar = ({
  classes,
  label,
  onNavigate,
  date,
  view,
  views,
  onView,
}) => {
  const onBackPressed = () => {
    onNavigate('back', moment(date).subtract(1, view).toDate())
  }

  const onNextPressed = () => {
    onNavigate('next', moment(date).add(1, view).toDate())
  }

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <IconButton onClick={onBackPressed}>
          <ChevronLeft />
        </IconButton>
        <Typography variant='subtitle1'>{label}</Typography>
        <IconButton onClick={onNextPressed}>
          <ChevronRight />
        </IconButton>
      </div>

      <ButtonGroup size='small' color='primary' className={classes.buttons}>
        {views.map((v) => (
          <Button
            key={v}
            variant={v === view ? 'contained' : 'outlined'}
            onClick={() => onView(v)}
          >
            {v}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

CalendarToolbar.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  views: PropTypes.array.isRequired,
  view: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  onView: PropTypes.func.isRequired,
}

export default withStyles(styles)(CalendarToolbar)
