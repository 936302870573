import { colors } from '@material-ui/core'

const names = [
  'green',
  'red',
  'orange',
  'grey',
  'blue',
  'purple',
  'lightGreen',
  'deepOrange',
  'blueGrey',
  'pink',
  'cyan',
  'deepPurple',
  'amber',
  'indigo',
  'brown',
  'lime',
  'teal',
  'yellow',
  'lightBlue',
]
const shades = [500, 300, 700, 900]

const unsortedShades = names.map((name) => {
  return shades.map((shade) => colors[name][shade])
})
const selectedColors = []
shades.forEach((shade, i) => {
  unsortedShades.forEach((color) => {
    selectedColors.push(color[i])
  })
})

export default selectedColors
