import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

const appId = 'area-web'
const next = window.location.origin
const FID_LOGIN_PAGE = process.env.REACT_APP_AUTHORIZE_PAGE
const CONNECT_URL = `${FID_LOGIN_PAGE}?app_id=${appId}&next=${next}`

const styles = () => ({
  buttonWrapper: {
    width: '96vw',
    height: '95vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  button: {
    outline: 'none',
    backgroundColor: 'white',
    color: 'black',
    padding: '25px 38px',
    border: '2px solid black',
    fontSize: '29px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111',
      color: 'white',
      transition: 'all 0.4s',
    },
    fontFamily: 'NeoSans',
  },
  imageWrapper: {
    padding: '20px',
  },
  logo: {
    maxWidth: '350px',
    height: 'auto',
  },
})

const LoginPage = ({ classes }) => {
  const connect = () => {
    document.location.assign(CONNECT_URL)
  }
  return (
    <div className={classes.buttonWrapper}>
      <div className={classes.imageWrapper}>
        <img
          alt='Area Logo'
          className={classes.logo}
          src='AREA2071_looped.gif'
        />
      </div>
      <button className={classes.button} type='button' onClick={connect}>
        Connect to FUTURE ID
      </button>
    </div>
  )
}

LoginPage.propTypes = {
  classes: PropTypes.any,
}

export default withStyles(styles)(LoginPage)
