import React from 'react'
import {
  Edit,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  required,
  TabbedForm,
  FormTab,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { GET_ONE } from 'ra-core'
import { privacyChoices } from './constants'
// import TagsInput from './fields/TagsInput'
import MembersInviteInput from './fields/MembersInviteInput'
import dataProvider from '../dataProvider'
import CommunityUpdateTab from './updates/CommunityUpdateTab'
import CommunityDiscussionTab from './updates/CommunityDiscussionTab'
import CommunityMembersTab from './updates/CommunityMembersTab'

const styles = () => ({
  communityImage: {
    maxHeight: '10rem',
  },
})

const CommunityEdit = ({ classes, ...props }) => {
  const communityId = props.match?.params?.id
  const [currentImageUrl, setCurrentImageUrl] = React.useState(null)

  const fetchImage = React.useCallback(async () => {
    if (communityId) {
      const community = await dataProvider(GET_ONE, `communities`, {
        id: communityId,
      })
      setCurrentImageUrl(community?.data?.imageUrl)
    }
  }, [communityId])

  React.useEffect(() => {
    fetchImage()
  }, [fetchImage])
  return (
    <Edit {...props}>
      <TabbedForm variant='standard' margin='normal'>
        <FormTab label='About'>
          <TextInput source='name' validate={required()} />
          <TextInput source='location' validate={required()} />
          <TextInput source='description' validate={required()} fullWidth />
          <SelectInput
            source='privacy'
            choices={privacyChoices}
            validate={required()}
          />
          <img
            className={classes.communityImage}
            src={currentImageUrl}
            alt='current upload'
          />
          <ImageInput
            source='image'
            label='CommunityPicture'
            accept='image/*'
            multiple={false}
          >
            <ImageField source='src' title='title' />
          </ImageInput>
          <MembersInviteInput />
        </FormTab>

        <FormTab label='Updates'>
          <CommunityUpdateTab />
        </FormTab>
        <FormTab label='Discussions'>
          <CommunityDiscussionTab />
        </FormTab>
        <FormTab label='Members'>
          <CommunityMembersTab />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
CommunityEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}
export default withStyles(styles)(CommunityEdit)
