import moment from 'moment-timezone'
import { loadToken } from '../service/TokenService'

const apiV2 = process.env.REACT_APP_WEB_API_V2_URL
const apiV3 = process.env.REACT_APP_WEB_API_V3_URL

const getBookingUrl = (id) => {
  const endPoint = `room-bookings/${id}`
  return `${apiV3}/${endPoint}`
}

const getHeader = () => ({
  'Content-Type': 'application/json',
  Authorization: loadToken(),
})

export const updateBooking = (id, data) => {
  return window.fetch(`${getBookingUrl(id)}`, {
    headers: getHeader(),
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export const deleteBooking = (id, isDeletingRecurrentEvents = false) => {
  return window.fetch(
    `${getBookingUrl(id)}/?deleteAllRecurring=${isDeletingRecurrentEvents}`,
    {
      headers: getHeader(),
      method: 'DELETE',
    },
  )
}

export const changeRoomOfBooking = (bookingId, newRoomId) => {
  return window.fetch(`${getBookingUrl(bookingId)}/room`, {
    headers: getHeader(),
    method: 'POST',
    body: JSON.stringify({
      meetingRoomId: newRoomId,
    }),
  })
}

export const updateAutoCancelStatus = (bookingId, status) => {
  return window.fetch(
    `${getBookingUrl(bookingId)}/auto-cancel/${status ? 'enable' : 'disable'}`,
    {
      headers: getHeader(),
      method: 'POST',
    },
  )
}

export const checkAvailability = async (startDate, endDate) => {
  const mmStartDate = moment.isMoment(startDate) ? startDate : moment(startDate)
  const mmEndDate = moment.isMoment(endDate) ? endDate : moment(endDate)

  return new Promise((resolve, reject) => {
    const startDateDubai = window.encodeURIComponent(mmStartDate.format())
    const endDateDubai = window.encodeURIComponent(mmEndDate.format())
    const query = `start_time=${startDateDubai}&end_time=${endDateDubai}`
    const url = `${apiV2}/book/check-booking-availability?${query}`
    return window
      .fetch(url, {
        headers: getHeader(),
        method: 'GET',
      })
      .then((res) => res.json())
      .then((resJson) => {
        resolve(resJson.rooms_available)
      })
      .catch((err) => reject(err))
  })
}
