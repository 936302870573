import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import placeholder from '../../common/assets/placeholder.png'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    marginRight: 10,
  },
  title: {
    margin: '5px 0px',
  },
  description: {
    color: '#FB5F26',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})
const FullnameField = ({ record }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <img className={classes.avatar} src={record.imageUrl ?? placeholder} />
      <div className={classes.innerContainer}>
        <p
          className={classes.title}
        >{`${record.firstName} ${record.lastName}`}</p>
        <a href={`mailto:${record.email}`} className={classes.description}>
          {record.email}
        </a>
      </div>
    </div>
  )
}

FullnameField.propTypes = {
  record: PropTypes.object,
}

FullnameField.defaultProps = {
  addLabel: true,
}

export default FullnameField
