// components that offer inputs for recurrent event options
import React from 'react'
import PropTypes from 'prop-types'
import SelectOptionField from './SelectOptionField'
import {
  TextField,
  withStyles,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

const endRecurrentTypeOptions = {
  lastBookingDate: 'On',
  occurrences: 'After',
}

const recurringFrequencyOptions = {
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
}

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recurrenceTypeField: {
    flex: 1,
    marginRight: 8,
    marginBottom: 8,
  },
})

// function for mapping an end recurrent type to a default value
// if its 'lastBookingDate', then it defaults to end after today (new Date())
// if its number of occurrences, then it defaults to 1
const getDefaultEndRecurrentTypeOptions = (endType) => {
  switch (endType) {
    case 'lastBookingDate':
      return new Date()
    case 'occurrences':
      return 1
    default:
      return null
  }
}

const RecurrenceField = ({ onRecurringOptionChange, classes }) => {
  const [meetingType, setMeetingType] = React.useState('single')
  const [frequency, setFrequency] = React.useState('daily')
  const [endRecurrenceType, setEndRecurrenceType] = React.useState(
    'lastBookingDate',
  )
  const [endRecurrenceValue, setEndRecurrenceValue] = React.useState(
    getDefaultEndRecurrentTypeOptions(endRecurrenceType),
  )

  const onMeetingTypeChange = (e) => {
    setMeetingType(e.target.value)
  }

  const onFrequencyChange = (e) => setFrequency(e.target.value)
  const onEndRecurrenceTypeChange = (e) => setEndRecurrenceType(e.target.value)
  const onEndRecurrenceValueChange = (dateOrEvent) => {
    if (endRecurrenceType === 'lastBookingDate') {
      // the date object is returned directly from the date picker, so no ".target.value"
      setEndRecurrenceValue(dateOrEvent)
    } else {
      // it is the number of recurrence, hense parse int
      setEndRecurrenceValue(Number.parseInt(dateOrEvent.target.value))
    }
  }

  /**
   * Side effects
   */
  // When meeting type changes to single, it should give null to the parent component
  React.useEffect(() => {
    if (meetingType === 'single') {
      onRecurringOptionChange(null)
    } else {
      onRecurringOptionChange({
        frequency,
        endRecurrenceType,
        endRecurrenceValue,
      })
    }
  }, [
    meetingType,
    endRecurrenceValue,
    endRecurrenceType,
    frequency,
    onRecurringOptionChange,
  ])

  // also listen to changes of the end recurrence type
  // because changing the value would cause the change of the type of the endRecurrenceValue
  // here once the type is changed the endRecurrenceValue would be set to the default value of that new type
  React.useEffect(() => {
    setEndRecurrenceValue(getDefaultEndRecurrentTypeOptions(endRecurrenceType))
    onRecurringOptionChange({
      frequency,
      endRecurrenceType,
      endRecurrenceValue,
    })
    // no need to reset the recurrence value and such given changes in frequency and so
    // therefore suppressing the warnings here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endRecurrenceType])

  return (
    <>
      <FormControl>
        <FormLabel>Meeting Type</FormLabel>
        <RadioGroup
          row
          value={meetingType}
          onChange={onMeetingTypeChange}
          aria-label='meeting type'
          name='Meeting Type'
        >
          <FormControlLabel value='single' control={<Radio />} label='Single' />
          <FormControlLabel
            value='recurring'
            control={<Radio />}
            label='Recurring'
          />
        </RadioGroup>
      </FormControl>

      {meetingType !== 'single' && (
        <>
          <SelectOptionField
            name='frequency'
            className={classes.recurrenceTypeField}
            label='Recurring Frequency'
            value={frequency}
            onChange={onFrequencyChange}
            options={recurringFrequencyOptions}
          />
          <div className={classes.container}>
            <SelectOptionField
              className={classes.recurrenceTypeField}
              fullWidth={false}
              margin='normal'
              name='endRecurrenceType'
              label='End recurrence'
              value={endRecurrenceType}
              onChange={onEndRecurrenceTypeChange}
              options={endRecurrentTypeOptions}
            />
            {endRecurrenceType === 'lastBookingDate' && (
              <DatePicker
                format='DD/MM/yyyy'
                margin='normal'
                variant='inline'
                value={endRecurrenceValue}
                onChange={onEndRecurrenceValueChange}
              />
            )}
            {endRecurrenceType === 'occurrences' && (
              <TextField
                label='Occurences'
                type='number'
                value={endRecurrenceValue}
                onChange={onEndRecurrenceValueChange}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}
RecurrenceField.propTypes = {
  onRecurringOptionChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(RecurrenceField)
