import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
})

const AttachmentFiles = ({ files = [], onDelete, classes }) => {
  return (
    <>
      <Typography className={classes.header}>File Attachments:</Typography>
      {files.map((file) => (
        <div key={file.id}>
          <a href={file.url} download>
            {file.fileName}
          </a>
          <Button
            onClick={() => {
              onDelete(file.id)
            }}
            className={classes.button}
          >
            Delete
          </Button>
        </div>
      ))}
      {!files.length && (
        <Typography variant='body2'>
          No file Attachments were added yet
        </Typography>
      )}
      <br />
    </>
  )
}

AttachmentFiles.propTypes = {
  files: PropTypes.array,
  onDelete: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(styles)(AttachmentFiles)
