import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

const MessageActiveStatus = ({ record }) =>
  record.isActive ? <CheckIcon /> : <CloseIcon />

MessageActiveStatus.propTypes = {
  record: PropTypes.any,
}

export default MessageActiveStatus
