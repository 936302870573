import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import {
  Toolbar,
  useCreate,
  useRedirect,
  useNotify,
  SaveButton,
} from 'react-admin'
import { toolbarStyles } from './styles'

const MessageCreateToolbar = (props) => (
  <Toolbar {...props} classes={toolbarStyles()}>
    <CreateButton {...props} />
  </Toolbar>
)

const CreateButton = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [create] = useCreate('area-tv-cms/active-message')
  const formState = useFormState()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath } = props

  const handleClick = useCallback(() => {
    const { values, valid } = formState
    if (!valid) {
      return
    }

    setLoading(true)
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setLoading(false)
          notify('ra.notification.created', 'info', { smart_count: 1 })
          redirectTo(basePath)
        },
        onFailure: () => {
          notify('Could not create a message', 'warning')
          setLoading(false)
        },
      },
    )
  }, [formState, create, redirectTo, notify, basePath])

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      disabled={isLoading}
    />
  )
}

CreateButton.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export default MessageCreateToolbar
