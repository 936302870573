import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from 'react-admin'
import { loadToken } from './service/TokenService'
import { stringify, parse } from 'query-string'
import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_URL,
  ARCHIVE_DELETE,
  PUT_UPDATE,
  VERIFY_COMMUNITY,
  UNVERIFY_USER,
  ASSIGN_LABELS,
  DEASSIGN_LABELS,
  DELETE_LABELS,
} from './common/requestTypes'
import axios from 'axios'
import {
  convertMeetingRoomLoad,
  createMeetingRoom,
  convertMeetingRoomsList,
} from './rooms/converter/RoomRequestConverter'
import {
  createCommunity,
  communityGetOne,
} from './community/converter/CommunityRequestConverter'
import {
  communityEventGetOne,
  createCommunityEvent,
} from './community/converter/CommunityEventRequestConverter'
import {
  communityUpdateGetOne,
  createCommunityUpdate,
} from './community/updates/CommunityUpdateRequestConverter'
import { createMessage } from './messages/messageRequestConverter'
import { convertReportsList } from './reports/converter/ReportRequestConverter'

const API_V1_URL =
  process.env.REACT_APP_WEB_API_V1_URL ||
  'https://area-api-future-id-interceptor-develop.apps.moti.us/api'
const API_V2_URL =
  process.env.REACT_APP_WEB_API_V2_URL ||
  'https://area-api-future-id-interceptor-develop.apps.moti.us/api/v2'
const API_V3_URL =
  process.env.REACT_APP_WEB_API_V3_URL ||
  'https://area-api-future-id-interceptor-develop.apps.moti.us/api/v3'
const API_V4_URL =
  process.env.REACT_APP_WEB_API_V4_URL ||
  'https://area-api-future-id-interceptor-develop.apps.moti.us/api/v4'

const API_V1_RESOURCES = [
  'meeting-room',
  'person',
  'news',
  'book',
  'report',
  'profile',
  'tags',
]

const API_V3_RESOURCES = [
  // all endpoints with "room-bookings" are v3, except room-bookings/iCal, which is v2...
  // eslint-disable-next-line
  new RegExp('room-bookings/(?!iCal).*'),
]

const API_V4_RESOURCES = ['discussions']

function isObject(value) {
  return typeof value === 'object' && !Array.isArray(value)
}

const filterData = (data, filter) => {
  if (typeof filter === 'string') {
    return data.toLowerCase().includes(filter.toLowerCase())
  } else if (typeof filter === 'boolean' || typeof filter === 'number') {
    return data === filter
  } else if (
    typeof filter === 'object' &&
    !Array.isArray(filter) &&
    !Array.isArray(data)
  ) {
    return Object.keys(filter).every((key) => {
      return key in data && filterData(data[key], filter[key])
    })
  } else if (Array.isArray(data) && isObject(filter)) {
    const filterKeys = Object.keys(filter)
    return data.some((value) =>
      filterKeys.every((filterKey) => value[filterKey] === filter[filterKey]),
    )
  }
}

const applyFiltering = (value, filter, resource) => {
  // No filtering for updates and events until backend development
  if (resource === 'communityEvents') return true
  if (resource === 'communityUpdates') return true

  if (filter && Object.keys(filter).length > 0) {
    return Object.keys(filter).every((key) => {
      return key in value && filterData(value[key], filter[key])
    })
  }
  return true
}

const applySorting = (a, b, order, field) => {
  const compare =
    typeof a[field] === 'string'
      ? a[field].toLowerCase() > b[field].toLowerCase()
      : a[field] > b[field]
  if (compare) return order === 'ASC' ? 1 : -1
  if (!compare) return order === 'ASC' ? -1 : 1
  return 1
}

const setIds = (x, index, resource) => {
  const baseResource = resource.split('/')[0]
  if (!x.id) {
    // For items do not have ID
    if (x[`${baseResource}Id`]) {
      x.id = x[`${baseResource}Id`]
    } else if (x[`${baseResource.slice(0, -4)}Id`]) {
      x.id = x[`${baseResource.slice(0, -4)}Id`]
    } else if (baseResource === 'group-rooms') {
      x.id = x.groupId
    } else if (baseResource === 'meeting-rooms') {
      x.id = x.id || x.meetingRoomId
      x.roomId = x.roomId || x.meetingRoomId
    } else if (baseResource === 'tags') {
      x.id = x.tagId
      x.name = x.tag
    } else {
      x.id = index
    }
  }
  return x
}
const normalizeResponseData = (resource, data) => {
  if (resource === 'tags/community' || resource === 'tags/communityEvent') {
    return data.tags
  }
  if (resource === 'meeting-rooms') {
    return convertMeetingRoomsList(data)
  }
  if (resource.startsWith('meeting-rooms/bookings')) {
    return data.bookings
  }
  if (resource === 'communities') {
    return data.communities
  }

  if (resource === 'communityEvents') {
    return data.events
  }

  if (resource.includes('discussions')) {
    return data.discussions
  }

  if (resource.includes('members')) {
    return data.members
  }

  /**
   * Label returns the following format:
   * {
   *    personLabels: [
   *        {
   *            labelId,
   *            label,
   *            ...
   *        }
   *    ]
   * }
   *
   * Here converts the above payload to:
   * [
   *      { id, label, ...}
   * ]
   */
  if (resource === 'persons/labels') {
    return data.personLabels.map(({ labelId: id, ...label }) => ({
      id,
      ...label,
    }))
  }

  if (resource === 'active-message') {
    return data.messages
  }

  if (resource === 'report') {
    return convertReportsList(data)
  }

  return data
}

const convertGetListRequestOptions = (resource, params, API_VERSION) => {
  let url
  const communityFilter =
    params && params.filter && params.filter.community
      ? params.filter.community
      : null

  if (resource.startsWith('communityEvents')) {
    url =
      communityFilter && communityFilter.id
        ? `${API_VERSION}/communities/${communityFilter.id}/events`
        : `${API_VERSION}/communities/events/all`
  } else if (resource.startsWith('communityUpdates')) {
    url =
      communityFilter && communityFilter.id
        ? `${API_VERSION}/communities/${communityFilter.id}/updates`
        : `${API_VERSION}/communities/updates/all`
  } else if (resource.startsWith('active-message')) {
    url = `${API_VERSION}/area-tv-cms/active-message/all`
  } else {
    url = `${API_VERSION}/${resource}`
  }
  return url
}

const convertGetOneRequestOptions = (resource, params, API_VERSION) => {
  let url
  const searchString = window.location.hash.split('?')[1]
  const search = parse(searchString)
  if (resource.endsWith('/all')) {
    url = `${API_VERSION}/${resource.slice(0, -4)}/${params.id}`
  } else if (resource.startsWith('profile')) {
    url = `${API_VERSION}/${resource}`
  } else if (resource.startsWith('communityEvents')) {
    url = search.communityId
      ? `${API_VERSION}/communities/${search.communityId}/events/${params.id}`
      : `${API_VERSION}/communities/events/all`
  } else if (resource.startsWith('communityUpdates')) {
    url = search.communityId
      ? `${API_VERSION}/communities/${search.communityId}/updates/${params.id}`
      : `${API_VERSION}/communities/updates/all`
  } else if (resource.startsWith('active-message')) {
    url = `${API_VERSION}/area-tv-cms/messages/${params.id}`
  } else {
    url = `${API_VERSION}/${resource}/${params.id}`
  }
  return url
}

const convertCreateRequestOptions = (resource, params, API_VERSION) => {
  let data = params.data
  let url = `${API_VERSION}/${resource}`
  if (resource === 'meeting-rooms') {
    data = createMeetingRoom(params.data)
  } else if (resource === 'communities') {
    data = createCommunity(params.data)
  } else if (resource.startsWith('communityEvents')) {
    data = createCommunityEvent(params.data)
    url = `${API_VERSION}/communities/${params.data.communityId}/events`
  } else if (resource.startsWith('communityUpdates')) {
    data = createCommunityUpdate(params.data)
    url = `${API_VERSION}/communities/${params.data.communityId}/updates`
  } else if (resource === 'tags') {
    url = `${API_VERSION}/tags`
  }

  return {
    url,
    options: { method: 'POST', data },
  }
}

const convertUpdateRequestOptions = (resource, params, API_VERSION) => {
  let data = params.data
  const method =
    resource.startsWith('communities') ||
    resource.startsWith('meeting-rooms') ||
    resource.startsWith('group-rooms') ||
    resource.startsWith('community') ||
    resource.startsWith('news') ||
    resource.startsWith('persons/labels') ||
    resource.startsWith('active-message')
      ? 'PATCH'
      : 'POST'

  let url = `${API_VERSION}/${resource}/${params.id}`

  if (resource.startsWith('meeting-rooms')) {
    data = createMeetingRoom(params.data)
  } else if (resource === 'communities') {
    data = createCommunity(params.data)
  } else if (resource.startsWith('communityEvents')) {
    url = `${API_VERSION}/communities/${params.data.community.id}/events/${params.data.id}`
    data = createCommunityEvent(params.data)
  } else if (resource.startsWith('communityUpdates')) {
    url = `${API_VERSION}/communities/${params.data.community.id}/updates/${params.data.id}`
    data = createCommunityUpdate(params.data)
  } else if (resource.startsWith('active-message')) {
    url = `${API_VERSION}/area-tv-cms/messages/${params.id}`
    data = createMessage(params.data)
  } else if (resource.startsWith('group-rooms')) {
    data = { name: params.data.name, description: params.data.description }
  }

  return {
    url,
    options: { method, data },
  }
}

const convertDeleteRequestOptions = (resource, params, API_VERSION) => {
  let url
  let finalParams
  const data = params?.data
  if (resource.startsWith('communityEvents')) {
    url = `${API_VERSION}/communities/${data.community.id}/events/${data.id}`
  } else if (resource.startsWith('communityUpdates')) {
    url = `${API_VERSION}/communities/${data.community.id}/updates/${data.id}`
  } else if (resource.startsWith('room-bookings')) {
    url = `${API_VERSION}/${resource}`
    finalParams = params.data
  } else {
    url =
      API_VERSION === API_V1_URL
        ? `${API_VERSION}/${resource}`
        : `${API_VERSION}/${resource}/${params.id}`
  }

  return {
    url,
    options: { method: 'DELETE', data: finalParams },
  }
}

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  const defaultOptions = {
    headers: {
      Authorization: loadToken(),
    },
  }

  const API_VERSION = API_V1_RESOURCES.some(
    (v1Resource) =>
      (resource.startsWith(v1Resource) &&
        !resource.startsWith('meeting-rooms') &&
        !resource.startsWith('book') &&
        !resource.startsWith('persons')) ||
      resource === 'tags/communityEvent' ||
      resource === 'tags/community',
  )
    ? API_V1_URL
    : API_V3_RESOURCES.some((v3Resource) => v3Resource.test(resource))
    ? API_V3_URL
    : API_V4_RESOURCES.some((v4Resource) => resource.includes(v4Resource))
    ? API_V4_URL
    : API_V2_URL

  switch (type) {
    case GET_LIST: {
      const url = convertGetListRequestOptions(resource, params, API_VERSION)
      return { url, options: { ...defaultOptions } }
    }
    case GET_ONE: {
      const url = convertGetOneRequestOptions(resource, params, API_VERSION)
      return { url, options: { ...defaultOptions } }
    }
    case GET_MANY: {
      const url =
        API_VERSION === API_V1_URL &&
        !resource.includes('/all') &&
        !(resource === 'tags/communityEvent') &&
        !(resource === 'tags/community')
          ? `${API_VERSION}/${resource}/all`
          : `${API_VERSION}/${resource}`
      return { url, options: { ...defaultOptions } }
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      }
      const url = `${API_VERSION}/${resource}?${stringify(query)}`
      return { url, options: { ...defaultOptions } }
    }
    case UPDATE: {
      const { url, options } = convertUpdateRequestOptions(
        resource,
        params,
        API_VERSION,
      )
      return { url, options: { ...defaultOptions, ...options } }
    }
    case CREATE: {
      const { url, options } = convertCreateRequestOptions(
        resource,
        params,
        API_VERSION,
      )
      return { url, options: { ...defaultOptions, ...options } }
    }
    case DELETE: {
      const { url, options } = convertDeleteRequestOptions(
        resource,
        params,
        API_VERSION,
      )
      return { url, options: { ...defaultOptions, ...options } }
    }
    case UPLOAD_IMAGE: {
      const { id, imageData } = params
      const url = `${API_VERSION}/${resource}/${id}/image`
      const options = { method: 'POST', data: imageData }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case UPLOAD_IMAGE_URL: {
      const { id, imageUrl } = params
      const url = `${API_VERSION}/${resource}/${id}/imageUrl`
      const options = { method: 'POST', data: { url: imageUrl } }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case ARCHIVE_DELETE: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'POST' }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case PUT_UPDATE: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'PUT', data: { ...params } }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case VERIFY_COMMUNITY: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'POST', data: { ...params } }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case UNVERIFY_USER: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'DELETE' }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case ASSIGN_LABELS: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'POST' }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case DEASSIGN_LABELS: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'DELETE' }
      return { url, options: { ...defaultOptions, ...options } }
    }
    case DELETE_LABELS: {
      const url = `${API_VERSION}/${resource}`
      const options = { method: 'DELETE' }
      return { url, options: { ...defaultOptions, ...options } }
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
}

/**
 * @param {Object} response HTTP response from axios
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params,
) => {
  const json = response.data
  const { ids } = params || {}
  const { page, perPage } =
    params && params.sort ? params.pagination : { page: 1, perPage: Infinity }
  const { field, order } = params && params.sort ? params.sort : {}
  const { filter } = params || {}

  const API_VERSION = API_V1_RESOURCES.some((v1Resource) =>
    resource.startsWith(v1Resource),
  )
    ? 1
    : 2

  let jsonData = json
  if (
    API_VERSION !== 1 &&
    type !== GET_ONE &&
    type !== VERIFY_COMMUNITY &&
    resource !== 'communityEvents' &&
    json.options
  ) {
    jsonData = json[Object.keys(json).find((key) => key !== 'options')].map(
      (x) => x,
    )
  }

  switch (type) {
    case GET_LIST:
      // eslint-disable-next-line
      let result = normalizeResponseData(resource, jsonData).filter((value) =>
        applyFiltering(value, filter, resource),
      )
      return {
        data: result
          .sort((a, b) => applySorting(a, b, order, field))
          .slice((page - 1) * perPage, page * perPage)
          .map((x, index) => setIds(x, index, resource)),
        total: result.length,
      }
    case CREATE:
      if (
        resource.startsWith('meeting-rooms') ||
        resource.startsWith('group-rooms') ||
        resource === 'tags'
      ) {
        return { data: setIds(jsonData, null, resource) }
      } else if (
        resource === 'communityEvents' ||
        resource.startsWith('meeting-room')
      ) {
        return { data: jsonData }
      } else if (resource === 'persons/labels') {
        return { data: { ...params.data, id: json.labelId } }
      } else if (resource === 'book/book-room') {
        return { data: jsonData }
      } else {
        return { data: { ...params.data, id: json.id } }
      }
    case GET_MANY:
      return {
        data: normalizeResponseData(resource, jsonData)
          .filter((data) => ids.includes(data[`${resource}Id`]))
          .map((x, index) => setIds(x, index, resource)),
      }
    case GET_ONE: {
      if (
        resource.startsWith('meeting-rooms') ||
        resource.startsWith('group-rooms')
      ) {
        return convertMeetingRoomLoad({
          ...jsonData,
          ...setIds(jsonData, null, resource),
        })
      } else if (resource === 'communities') {
        return communityGetOne(jsonData)
      } else if (resource.startsWith('communityUpdates')) {
        return communityUpdateGetOne(jsonData)
      } else if (resource.startsWith('communityEvent')) {
        return communityEventGetOne(jsonData)
      } else {
        return { data: jsonData }
      }
    }
    default:
      return { data: json }
  }
}

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params,
  )

  return axios
    .request({ url, ...options })
    .then((response) =>
      convertHTTPResponseToDataProvider(response, type, resource, params),
    )
}
