import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'
import MessageCreateToolbar from './MessageCreateToolbar'
import { isValidDate, isDateInFuture } from '../common/validators'
import DateTimePicker from '../common/fields/DateTimePicker'

const MessageCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      variant='standard'
      margin='normal'
      redirect='show'
      toolbar={<MessageCreateToolbar />}
    >
      <TextInput multiline source='message' validate={required()} fullWidth />
      <DateTimePicker
        source='expiry'
        label='Expiry date'
        validate={[required(), isValidDate, isDateInFuture]}
      />
    </SimpleForm>
  </Create>
)

export default MessageCreate
