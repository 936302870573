import { loadToken } from '../service/TokenService'

const getPolicyUrl = (groupId) => {
  const apiUrl = process.env.REACT_APP_WEB_API_V2_URL
  const endPoint = `group-rooms/${groupId}/policies`
  return `${apiUrl}/${endPoint}`
}

const getHeader = () => ({
  'Content-Type': 'application/json',
  Authorization: loadToken(),
})

export const patchPolicy = (groupId, data) => {
  delete data.id
  return window.fetch(getPolicyUrl(groupId), {
    headers: getHeader(),
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}
