import React from 'react'
import { List, Datagrid, TextField, NumberField } from 'react-admin'
import ActionButton from './fields/ActionButton'
import AmenitiesList from './fields/AmenitiesList'

export const RoomsList = (props) => (
  <List
    {...props}
    sort={{ field: 'created_at', order: 'DESC' }}
    title='Rooms'
    perPage={15}
    bulkActionButtons={false}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <NumberField source='capacity' />
      <AmenitiesList label='Attributes' source='amenities' />
      <ActionButton />
    </Datagrid>
  </List>
)

export default RoomsList
