import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
})

const ImageFieldWithSize = ({ source, record, maxHeight, title, classes }) => {
  return (
    <img
      src={record[source]}
      alt={title}
      title={title}
      style={{ maxHeight }}
      className={classes.image}
    />
  )
}

ImageFieldWithSize.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  maxHeight: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object,
}

ImageFieldWithSize.defaultProps = {
  addLabel: true,
}

export default withStyles(styles)(ImageFieldWithSize)
