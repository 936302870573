import React from 'react'
import PropTypes from 'prop-types'
import { FieldTitle } from 'ra-core'
import { DateTimePicker as MaterialDateTimePicker } from '@material-ui/pickers'
import { useInput, InputHelperText } from 'react-admin'

function DateTimePicker({
  options,
  resource,
  source,
  label,
  validate,
  ...rest
}) {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    resource,
    source,
    validate,
    ...rest,
  })

  return (
    <div className='picker'>
      <MaterialDateTimePicker
        {...options}
        {...input}
        id={id}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        margin='normal'
        error={!!(touched && error)}
        value={input.value ? input.value : null}
        helperText={<InputHelperText touched={touched} error={error} />}
      />
    </div>
  )
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
}

DateTimePicker.defaultProps = {
  options: {
    format: 'dd/MM/yyyy, hh:mm a',
    ampm: true,
    clearable: false,
  },
}

export default DateTimePicker
