import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '0',
    width: '45rem',
    '& h3': {
      marginTop: '3rem',
    },
  },
  saveButton: {
    width: '10rem!important',
    marginBottom: '2rem',
  },
  textField: {
    display: 'inline-flex',
    margin: '1rem 0',
    'MuiInputBase-root': {
      marginTop: '2rem',
    },
  },
  labelRoot: {
    top: '-0.5rem',
    fontSize: '1.5rem',
  },
  inputRoot: {
    input: { marginTop: '2rem' },
  },
})

const formatBackendToFrontendTime = (backendTime) => {
  return backendTime && backendTime.length >= 5
    ? backendTime.substr(0, 5)
    : backendTime
}

const PolicySelect = ({ record }) => {
  const form = useForm()
  const [policies, setPolicies] = useState()
  const classes = useStyles()
  if (!policies && record.bookingPolicies) {
    setPolicies(record.bookingPolicies)
  }

  if (!policies) return null

  const textFieldStyling = {
    InputLabelProps: {
      classes: {
        root: classes.labelRoot,
      },
    },
    className: classes.textField,
  }

  const change = (fieldName) => (ev) => {
    const updatedPolicies = { ...policies, [fieldName]: ev.target.value }
    form.change(`bookingPolicies.${fieldName}`, ev.target.value)
    setPolicies(updatedPolicies)
  }

  const changeTime = (fieldName) => (ev) => {
    const convertedTime = `${ev.target.value}:00+04`
    setPolicies({ ...policies, [fieldName]: convertedTime })
  }

  return (
    <div className={classes.container}>
      <h3>Group Policies</h3>
      <TextField
        {...textFieldStyling}
        label='Minimum booking duration in minutes'
        value={policies.minBookingDurationMinutes}
        onChange={change('minBookingDurationMinutes')}
      />
      <TextField
        {...textFieldStyling}
        label='Maximum booking duration in minutes'
        value={policies.maxBookingDurationMinutes}
        onChange={change('maxBookingDurationMinutes')}
      />

      <TextField
        {...textFieldStyling}
        label='Maximum bookings per day per user (protect from spamming)'
        value={policies.maxBookingsPerDayAllowed}
        onChange={change('maxBookingsPerDayAllowed')}
      />
      <TextField
        {...textFieldStyling}
        label='Maximum of active bookings a user can have at any time'
        value={policies.maxBookingsPerUserAllowed}
        onChange={change('maxBookingsPerUserAllowed')}
      />
      <TextField
        {...textFieldStyling}
        label='Maximum of parallel bookings a user can have'
        value={policies.maxParallelBookingsAllowed}
        onChange={change('maxParallelBookingsAllowed')}
      />
      <TextField
        {...textFieldStyling}
        label='Maximum consecutive bookings of the same room'
        value={policies.maxConsecutiveBookingsAllowed}
        onChange={change('maxConsecutiveBookingsAllowed')}
      />
      <TextField
        {...textFieldStyling}
        label='Maximum amount of days a room can be booked ahead'
        value={policies.maxAdvanceBookingInDaysAllowed}
        onChange={change('maxAdvanceBookingInDaysAllowed')}
      />
      <TextField
        {...textFieldStyling}
        label='Ensure at least x% of all rooms at the same time are still available.'
        value={policies.freeRoomQuotaPercent}
        onChange={change('freeRoomQuotaPercent')}
      />

      <TextField
        {...textFieldStyling}
        label='Working Time start'
        type='time'
        defaultValue='10:30'
        value={formatBackendToFrontendTime(policies.startWorkingTime)}
        onChange={changeTime('startWorkingTime')}
      ></TextField>
      <TextField
        {...textFieldStyling}
        label='Working Time End'
        type='time'
        defaultValue='10:30'
        value={formatBackendToFrontendTime(policies.endWorkingTime)}
        onChange={changeTime('endWorkingTime')}
        className={classes.textField}
      ></TextField>
    </div>
  )
}

PolicySelect.propTypes = {
  record: PropTypes.object,
}

export default connect(null, { push })(PolicySelect)
