import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Paper, Grid, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { GET_ONE, GET_LIST } from 'ra-core'
import { Authenticated } from 'react-admin'
import { withRouter } from 'react-router-dom'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../common/styles/calendarStyles.css'
import dataProvider from '../dataProvider'
import CalendarRoomSelector from './fields/CalendarRoomSelector'
import EventCalendar from './fields/EventCalendar'
import { Title } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  removeAllRoomsBtn: {
    marginTop: theme.spacing(1),
  },
  paper: {
    fontFamily: 'PT Sans',
    padding: '1em',
  },
  onTop: {
    zIndex: 10,
  },
  container: {
    maxWidth: 'calc(100vw - 80px)',
  },
  containerWithSidebar: {
    maxWidth: 'calc(100vw - 252px)',
  },
}))

const BookingList = ({ location, isSidebarOpen }) => {
  const [user, setUser] = useState({})
  const [meetingRooms, setMeetingRooms] = useState([])
  const [selectedRooms, setSelectedRooms] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const roomId = searchParams.get('roomId')
    if (roomId && meetingRooms.length) {
      const room = meetingRooms.find((room) => room.id.toString() === roomId)
      setSelectedRooms(room ? [{ value: room.id, label: room.name }] : [])
    }
  }, [location.search, meetingRooms])

  useEffect(() => {
    const getUserDetails = async () => {
      if (Object.keys(user).length) {
        return
      }
      const response = await dataProvider(GET_ONE, 'profile/me')
      setUser(response.data.person)
    }

    const getMeetingRoomList = async () => {
      if (meetingRooms.length) {
        return
      }
      const response = await dataProvider(GET_LIST, 'meeting-room')
      setMeetingRooms(response.data)
    }

    getUserDetails()
    getMeetingRoomList()
  }, [])

  const handleChange = (rooms) => {
    setSelectedRooms(rooms ?? [])
  }

  return (
    <Authenticated location={location}>
      <Paper className={classes.paper}>
        <Title title='Bookings' />
        <Grid
          container
          spacing={3}
          direction='column'
          className={classnames({
            [classes.container]: !isSidebarOpen,
            [classes.containerWithSidebar]: isSidebarOpen,
          })}
        >
          <Grid item xs={12} className={classes.onTop}>
            <CalendarRoomSelector
              options={meetingRooms}
              value={selectedRooms}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <EventCalendar
              rooms={meetingRooms}
              roomIds={selectedRooms.map((room) => room.value)}
              allRoomIds={meetingRooms.map((room) => room.meetingRoomId)}
              user={user}
            />
          </Grid>
        </Grid>
      </Paper>
    </Authenticated>
  )
}

BookingList.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  isSidebarOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isSidebarOpen: state.admin.ui.sidebarOpen,
})

export default connect(mapStateToProps)(withRouter(BookingList))
