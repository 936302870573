import Cookies from 'js-cookie'

export const SESSION_TOKEN_KEY = 'session_token'
const TOKEN = 'token'
const ROLE = 'role'
const PERSON_ID = 'personId'

export const storeToken = (token) => {
  localStorage.setItem(TOKEN, token)
}

export const loadToken = () => {
  return localStorage.getItem(TOKEN)
}

export const setRole = (role) => {
  localStorage.setItem(ROLE, role)
}

export const loadRole = () => {
  return localStorage.getItem(ROLE)
}

export const setPersonId = (personId) => {
  localStorage.setItem(PERSON_ID, personId)
}

export const loadPersonId = () => {
  return localStorage.getItem(PERSON_ID)
}

export const logout = () => {
  localStorage.removeItem(TOKEN)
  localStorage.removeItem(ROLE)
  localStorage.removeItem(PERSON_ID)
  Cookies.remove(SESSION_TOKEN_KEY, defaultCookieOptions)
}

export const defaultCookieOptions = {
  domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
  expires: 60,
}
