import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import AvailabilityInput from './fields/AvailabilityInput'

const RoomCreate = (props) => (
  <Create {...props}>
    <SimpleForm variant='standard' margin='normal'>
      <ImageInput source='image' label='Related pictures' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>
      <TextInput source='name' />
      <NumberInput source='capacity' />
      <TextInput source='crestronId' />
      <TextInput source='instructions.en' multiline />
      <TextInput source='instructions.ar' multiline />
      <TextInput source='details.en' label='Details (en)' multiline fullWidth />
      <TextInput source='details.ar' label='Details (ar)' multiline fullWidth />
      <AvailabilityInput source='availability' />
      <ArrayInput source='amenities'>
        <SimpleFormIterator>
          <TextInput source='en' label='Amenity' />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source='amenities'>
        <SimpleFormIterator>
          <TextInput source='ar' label='Amenity' />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)

export default RoomCreate
