import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { List, Datagrid, TopToolbar, ExportButton } from 'react-admin'
import FullnameField from './fields/FullnameField'
import UserRoleSwitch from './fields/UserRoleSwitch'
import UserStatusSwitch from './fields/UserStatusSwitch'
import RoomGroupsProvider from './fields/RoomGroupsProvider'
import RoomGroupSelect from './fields/RoomGroupSelect'
import UserVerificationSwitch from './fields/UserVerificationSwitch'
import UserListFilter, { roleChoices } from './fields/UserListFilter'

const style = (theme) => {
  return {
    select: {
      minWidth: theme.spacing(20),
    },
  }
}

const ListActions = () => (
  <TopToolbar>
    <ExportButton maxResults={5000} />
  </TopToolbar>
)

export const UsersList = ({ classes, ...props }) => (
  <RoomGroupsProvider
    render={(roomGroups) => (
      <List
        {...props}
        filters={<UserListFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        title='Users'
        perPage={15}
        actions={<ListActions />}
      >
        <Datagrid>
          <FullnameField source='firstName' label='Name' />
          <UserRoleSwitch
            source='authRole'
            label='Role'
            choices={roleChoices}
            propertyName='role'
          />
          <RoomGroupSelect
            source='roomGroups'
            choices={roomGroups}
            selectClassName={classes.select}
            refreshOnChange
          />
          <UserVerificationSwitch source='verifiedByArea' label='Verified' />
          <UserStatusSwitch source='accountEnabled' />
        </Datagrid>
      </List>
    )}
  />
)

UsersList.propTypes = {
  classes: PropTypes.object,
}

const StyledUsersList = withStyles(style)(UsersList)
export default StyledUsersList
