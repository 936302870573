import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const EmptyRowsMessage = ({ message }) => {
  return (
    <Box textAlign='center' m={2}>
      <Typography>{message}</Typography>
    </Box>
  )
}

EmptyRowsMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default EmptyRowsMessage
