import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { showNotification } from 'react-admin'
import useRefresh from '../../common/useRefresh'
import { connect } from 'react-redux'
import {
  Switch,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core'
import dataProvider from '../../dataProvider'
import { VERIFY_COMMUNITY, UNVERIFY_USER } from '../../common/requestTypes'

const UserVerificationSwitch = ({ record, source, showNotification }) => {
  const refresh = useRefresh()
  const isUserVerified = record[source]
  const [openDialog, setOpenDialog] = useState(false)

  const handleChangeStatus = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleValueChangeConfirm = () => {
    const mutateType = isUserVerified ? UNVERIFY_USER : VERIFY_COMMUNITY

    dataProvider(mutateType, `persons/verify/${record.personId}`)
      .then(() => {
        setOpenDialog(false)
        showNotification('User verification status has been changed')
        refresh()
      })
      .catch((err) => {
        if (err) {
          showNotification(
            'User verification status could not be changed',
            'error',
          )
        }
        setOpenDialog(false)
      })
  }

  return (
    <>
      <Switch
        color='primary'
        checked={isUserVerified}
        onClick={handleChangeStatus}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {isUserVerified
            ? 'Are you sure you want to un-verify the user?'
            : 'Are you sure you want to verify the user?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Disagree
          </Button>
          <Button onClick={handleValueChangeConfirm} color='primary' autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

UserVerificationSwitch.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  showNotification: PropTypes.func.isRequired,
}

export default connect(null, {
  showNotification,
})(UserVerificationSwitch)
