import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import { useCreate, useRedirect, useNotify, SaveButton } from 'react-admin'
import { fileUpload, saveAttachmentLink } from './fileAttachmentService'

const CommunityUpdateCreateButton = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [create] = useCreate('communityUpdates')
  const formState = useFormState()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath } = props

  const uploadAttachments = useCallback(
    (updateId) => {
      const { values } = formState

      const filePromises = (values.attachments || []).map((file) =>
        fileUpload(values.communityId, updateId, file),
      )

      const linkPromises = (values.links || []).map((link) =>
        saveAttachmentLink(values.communityId, updateId, link),
      )

      return Promise.all([...filePromises, ...linkPromises])
    },
    [formState],
  )

  const handleClick = useCallback(() => {
    const { values, valid } = formState
    if (!valid) {
      return
    }

    setLoading(true)
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data: newRecord }) => {
          uploadAttachments(newRecord.id).then(() => {
            setLoading(false)
            notify('ra.notification.created', 'info', { smart_count: 1 })
            redirectTo(basePath)
          })
        },
        onFailure: () => {
          notify('Could not create a community update', 'warning')
          setLoading(false)
        },
      },
    )
  }, [formState, uploadAttachments, create, redirectTo, notify, basePath])

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      disabled={isLoading}
    />
  )
}

CommunityUpdateCreateButton.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export default CommunityUpdateCreateButton
