import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { showNotification } from 'react-admin'
import { connect } from 'react-redux'
import {
  Switch,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core'
import dataProvider from '../../dataProvider'
import { VERIFY_COMMUNITY } from '../../common/requestTypes'

const CommunityVerifySwitch = ({ record, showNotification }) => {
  const [currentStatus, setCurrentStatus] = useState(record.verified)
  const [newStatus, setNewStatus] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const handleChangeStatus = (e) => {
    e.stopPropagation()
    e.preventDefault()

    setOpenDialog(true)
    setNewStatus(!currentStatus)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleValueChangeConfirm = () => {
    const endpoint = newStatus ? 'verify' : 'unverify'

    dataProvider(
      VERIFY_COMMUNITY,
      `communities/${record.id}/verification/${endpoint}`,
      { verified: newStatus },
    )
      .then(() => {
        setCurrentStatus(newStatus)
        setOpenDialog(false)
        showNotification('Verified status has been changed')
      })
      .catch((err) => {
        if (err) {
          showNotification('Verified status could not be changed', 'error')
        }
        setOpenDialog(false)
      })
  }

  return (
    <div onClick={handleChangeStatus}>
      <Switch color='primary' checked={currentStatus} />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {'Are you sure you want to change the verification status?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Disagree
          </Button>
          <Button onClick={handleValueChangeConfirm} color='primary' autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

CommunityVerifySwitch.propTypes = {
  record: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
}

export default connect(null, {
  showNotification,
})(CommunityVerifySwitch)
