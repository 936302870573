import React, { useState } from 'react'
import { showNotification, TextField } from 'react-admin'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  MenuItem,
  DialogActions,
  Button,
  Select,
} from '@material-ui/core'
import dataProvider from '../../dataProvider'
import { PUT_UPDATE } from '../../common/requestTypes'
import { loadPersonId } from '../../service/TokenService'

const UserRoleSwitch = ({ source, record, showNotification, choices }) => {
  const [initialValue, setInitialValue] = useState(record[source])
  const [selectedValue, setSelectedValue] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const handleValueChange = (e) => {
    if (e.target.value !== initialValue) {
      setSelectedValue(e.target.value)
      setOpenDialog(true)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleValueChangeConfirm = () => {
    dataProvider(PUT_UPDATE, `person/${record.personId}/role`, {
      role: selectedValue,
    })
      .then(() => {
        setOpenDialog(false)
        setInitialValue(selectedValue)
        showNotification('User role changed')
      })
      .catch((err) => {
        if (err) {
          showNotification('User role could not be changed', 'warning')
        }
        setOpenDialog(false)
      })
  }

  const isUser = Number(loadPersonId()) === record.personId

  return !isUser ? (
    <>
      <Select value={initialValue} onChange={handleValueChange}>
        {choices.map((choice) => (
          <MenuItem key={choice.id} value={choice.id}>
            {choice.name}
          </MenuItem>
        ))}
      </Select>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {'Are you sure you want to change the role of the user?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Disagree
          </Button>
          <Button onClick={handleValueChangeConfirm} color='primary' autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <TextField record={{ role: record.authRole.toUpperCase() }} source='role' />
  )
}

UserRoleSwitch.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
}

export default connect(null, {
  showNotification,
})(UserRoleSwitch)
