import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNotify } from 'react-admin'
import { TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { getMembers } from '../../roomGroups/roomGroupService'
import { inviteMembers } from '../communityService'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  input: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  inviteButton: {
    marginTop: 20,
  },
}))

const MembersInviteInput = ({ record }) => {
  const [members, setMembers] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])
  const [isVisible, setIsVisible] = useState(true)
  const notify = useNotify()
  const classes = useStyles()

  useEffect(() => {
    getMembers().then((response) => {
      setMembers(response.data)
    })
  }, [])

  const invite = () => {
    if (!selectedMembers.length || !record) {
      return
    }

    const memberIds = selectedMembers.map((m) => m.personId)

    inviteMembers(record.id, memberIds)
      .then(() => {
        notify(`Successfully invited ${setSelectedMembers.length} users`)
        setIsVisible(false)
        setSelectedMembers([])

        window.setTimeout(() => {
          setIsVisible(true)
        }, 2)
      })
      .catch(() => notify('Could not invite users', 'warning'))
  }

  const getSelectableMembers = () =>
    members
      .filter((member) => {
        for (let i = 0; i < selectedMembers.length; i++) {
          if (selectedMembers.personId === selectedMembers[i].personId) {
            selectedMembers[i] = member
            return false
          }
        }
        return true
      })
      .sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1
        }
        if (a.lastName > b.lastName) {
          return 1
        }
        return 0
      })

  return isVisible ? (
    <div className={classes.container}>
      <Autocomplete
        className={classes.input}
        options={getSelectableMembers()}
        getOptionLabel={(member) => `${member.firstName} ${member.lastName}`}
        clearOnEscape={true}
        onChange={(e, value) => setSelectedMembers(value)}
        multiple
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant='standard'
              label='Invite members'
              fullWidth
            />
          )
        }}
      />
      <Button onClick={invite} className={classes.inviteButton}>
        Invite
      </Button>
    </div>
  ) : null
}

MembersInviteInput.propTypes = {
  record: PropTypes.object,
}

export default MembersInviteInput
