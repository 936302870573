import cloneDeep from 'lodash/cloneDeep'

export const amenitiesToInput = (amenities) => {
  const amenitiesArray = []
  if (amenities && amenities.en) {
    amenities.en.forEach((a, i) =>
      amenitiesArray.push({
        en: a,
        ar: amenities.ar[i],
      }),
    )
  }
  return amenitiesArray
}

export const amenitiesToApi = (amenities) => {
  if (amenities && amenities.length) {
    const en = []
    const ar = []
    amenities.forEach((a) => {
      en.push(a.en || '')
      ar.push(a.ar || '')
    })
    return { en, ar }
  }
  return amenities
}

// post to create room (used for update too)
export const createMeetingRoom = (data) => {
  return data.image ? formWithImage(data) : roomWithoutImageData(data)
}

// use json if there is no image
const roomWithoutImageData = (data) => {
  if (data.amenities) {
    data.amenities = amenitiesToApi(data.amenities)
  }
  return data
}

const formWithImage = (dataOriginal) => {
  const data = cloneDeep(dataOriginal)
  data.imageUrl && delete data.imageUrl
  const form = new window.FormData()
  form.append('name', data.name)
  form.append('crestronId', data.crestronId)
  form.append('capacity', data.capacity)
  form.append('details', JSON.stringify(data.details))
  data.amenities &&
    form.append('amenities', JSON.stringify(amenitiesToApi(data.amenities)))
  data.availability &&
    form.append('availability', JSON.stringify(data.availability))
  data.image && form.append('image', data.image.rawFile)
  return form
}

// load single room for detailed view
export const convertMeetingRoomLoad = (jsonData) => {
  return {
    data: {
      ...jsonData,
      amenities: amenitiesToInput(jsonData.amenities),
    },
  }
}

export const convertMeetingRoomsList = (jsonData) => {
  return jsonData.map((data) => ({
    ...data,
    amenities: amenitiesToInput(data.amenities),
  }))
}
