import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core'
import useBookings from '../useBookings'
import useBookingAvailability from '../useBookingAvailability'
import _ from 'lodash'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 0px',
    justifyContent: 'space-between',
  },
  time: {
    padding: '0px 4px',
  },
  index: {
    paddingRight: 8,
  },
  spacer: {
    flex: 1,
  },
  resolvedText: {
    color: theme.palette.primary.main,
  },

  resolveOptionsContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    // push items to the right
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: '0px 4px',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  conflictTimeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})
const ConflictingEventOptions = ({
  index,
  roomList,
  // we need this info here so that if user wants to change room
  // a createbooking request can be done directly from within this component
  booking,
  impossibleBooking,
  classes,
  onBookingResolved,
}) => {
  // constants for specifying a set of possible resolution methods
  const RESOLUTION_OPTIONS = {
    cancel: 'Cancel this booking',
    change: 'Change room for this booking',
  }

  // mark down the selected resolution method
  const [selectedResolution, setSelectedResolution] = React.useState(
    Object.keys(RESOLUTION_OPTIONS)[0],
  )

  // loading list of rooms
  const [availableRooms] = useBookingAvailability({
    startTime: new Date(impossibleBooking.startTime),
    endTime: new Date(impossibleBooking.endTime),
  })

  // marking the selected new room
  const [selectedRoom, setSelectedRoom] = React.useState('')

  // arming functions for creating booking of the changed rooms
  const { createBooking } = useBookings({
    shouldConvert: true,
    roomIds: selectedRoom,
    startTime: impossibleBooking.startTime,
    endTime: impossibleBooking.endTime,
  })

  // flag for the resubmission of booking in case the room is changing
  const [submittingBooking, setSubmittingBooking] = React.useState(false)

  // mark if this conflict has been resolved (since this line won't go away on decided, this flag is needed)
  const [resolved, setResolved] = React.useState(false)

  // the actuation of resolving the confict
  const resolve = async () => {
    // if the event is canceled, no need to do anything
    if (selectedResolution === 'change') {
      // submit a new booking request with the newly selected room
      setSubmittingBooking(true)
      await createBooking({
        // convert this recurring event to a single booking event
        ..._.omit(
          booking,
          'type,frequency,lastBookingDate,occurrence,roomIds'.split(','),
        ),
        // ... and denote it as a single event
        type: 'single',
        // ... and remember to change the room ID
        roomId: selectedRoom,
        // set the time specific to only this conflict
        startTime: impossibleBooking.startTime,
        endTime: impossibleBooking.endTime,
      })
      setSubmittingBooking(false)
    }

    // otherwise create new booking based on the selected room change, at the specific time
    setResolved(true)
    onBookingResolved(index)
  }

  const formatTime = (time) => moment(time).format('DD/MM/YYYY HH:mm')

  return (
    <div className={classes.container}>
      <div className={classes.index}>{index}:</div>
      <div className={classes.infoContainer}>
        <div className={classes.conflictTimeContainer}>
          <div className={classes.time}>
            {formatTime(impossibleBooking.startTime)}
          </div>
          <div>-</div>
          <div className={classes.time}>
            {formatTime(impossibleBooking.endTime)}{' '}
          </div>
        </div>
        {roomList && (
          <div>
            Intended booking room:{' '}
            {
              roomList.find(
                (room) =>
                  room.meetingRoomId.toString() ===
                  impossibleBooking.roomId.toString(),
              )?.name
            }
          </div>
        )}
      </div>
      {/* For pusing components left and right to aside */}
      <div className={classes.spacer} />
      {
        // depending on whether the conflict is resolved. If not, show the form,
        // if yes show the selected resolution
        !resolved ? (
          <div className={classes.resolveOptionsContainer}>
            {/* Regardless of the resolution method, show the list of resolution method for the user to choose */}
            <FormControl classes={{ root: classes.formControl }}>
              <InputLabel htmlFor={'resolution'}>Resolution:</InputLabel>
              <Select
                disabled={submittingBooking}
                name='resolution'
                value={selectedResolution}
                onChange={(e) => setSelectedResolution(e.target.value)}
              >
                {Object.entries(RESOLUTION_OPTIONS).map(([resKey, value]) => (
                  <MenuItem key={resKey} value={resKey}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* If the resolution method is to change the room, show the dropdown to select a new room */}
            {selectedResolution === 'change' && (
              <FormControl fullWidth classes={{ root: classes.formControl }}>
                <InputLabel htmFor={'newRoom'}>New Room:</InputLabel>
                <Select
                  name='newRoom'
                  value={selectedRoom}
                  onChange={(e) => setSelectedRoom(e.target.value)}
                  disabled={resolved || submittingBooking}
                >
                  {availableRooms.map((room) => (
                    <MenuItem
                      key={room.meetingRoomId}
                      value={room.meetingRoomId}
                    >
                      {room.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Render submit button or circular progress, depending on whether a room change is being submitted */}
            {submittingBooking ? (
              <CircularProgress />
            ) : (
              <Button
                color='primary'
                onClick={resolve}
                disabled={
                  selectedResolution === 'change' && selectedRoom === ''
                }
              >
                Resolve
              </Button>
            )}
          </div>
        ) : (
          // Display the selected resolution here
          // the changed room selection should remain at the dropdown on the left as a disabled selection
          <div className={classes.resolvedText}>
            {selectedResolution === 'cancel'
              ? 'Event cancelled'
              : 'Room changed'}
          </div>
        )
      }
    </div>
  )
}
ConflictingEventOptions.propTypes = {
  index: PropTypes.number.isRequired,
  roomList: PropTypes.arrayOf(
    // an entry of a room
    PropTypes.objectOf({
      // only these 2 attributes are needed
      meetingRoomId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  booking: PropTypes.object.isRequired,
  impossibleBooking: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onBookingResolved: PropTypes.func.isRequired,
}
export default withStyles(styles)(ConflictingEventOptions)
