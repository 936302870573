import React, { useEffect, useState } from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  makeStyles,
  Button,
} from '@material-ui/core'
import { DELETE, GET_LIST } from 'ra-core'
import dataProvider from '../../dataProvider'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import EmptyRowsMessage from '../../common/EmptyRowsMessage'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const CommunityDiscussionTab = ({ record }) => {
  const classes = useStyles()

  const [discussions, setDiscussions] = useState([])
  useEffect(() => {
    getDiscussions()
  }, [])

  const getDiscussions = () => {
    dataProvider(GET_LIST, `communities/${communityId}/discussions`).then(
      (response) => {
        setDiscussions(response.data)
      },
    )
  }

  const createData = ({
    id,
    name,
    description,
    createdAt,
    totalMessages,
    unreadCount,
  }) => {
    return {
      id,
      name,
      description,
      createdAt,
      totalMessages,
      unreadCount,
    }
  }

  const handleDelete = (id) => (e) => {
    e.stopPropagation()
    dataProvider(DELETE, 'discussions', {
      data: { id },
    }).then(() => getDiscussions())
  }

  const { id: communityId } = record
  const rows = discussions.map((update) => createData(update))

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Total Messages</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow className={classes.row} hover key={row.name}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell>{row.totalMessages}</TableCell>
              <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Button
                  onClick={handleDelete(row.id)}
                  color='primary'
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length === 0 && (
        <EmptyRowsMessage
          message={'There are no discussions in this community'}
        />
      )}
    </TableContainer>
  )
}

CommunityDiscussionTab.propTypes = {
  push: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
}

export default connect(null, {
  push,
})(CommunityDiscussionTab)
