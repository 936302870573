import React, { useEffect, useState } from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  makeStyles,
  Button,
} from '@material-ui/core'
import { DELETE, GET_LIST } from 'ra-core'
import dataProvider from '../../dataProvider'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import EmptyRowsMessage from '../../common/EmptyRowsMessage'

import FullnameField from '../../users/fields/FullnameField'
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const CommunityMembersTab = ({ record }) => {
  const classes = useStyles()

  const [members, setMembers] = useState([])
  useEffect(() => {
    getMembers()
  }, [])

  const getMembers = () => {
    dataProvider(GET_LIST, `communities/${communityId}/members`).then(
      (response) => {
        setMembers(response.data)
      },
    )
  }

  const createData = ({ person, createdAt }) => {
    return {
      id: person.personId,
      person,
      role: person.authRole,
      verified: person.verifiedByArea,
      createdAt,
    }
  }

  const handleDelete = (id) => (e) => {
    e.stopPropagation()
    dataProvider(DELETE, `communities/${communityId}/members`, {
      id,
    }).then(() => getMembers())
  }

  const { id: communityId } = record
  const rows = members.map((update) => createData(update))

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow hover key={row.name}>
              <TableCell component='th' scope='row'>
                <FullnameField record={row.person} />
              </TableCell>
              <TableCell>{row.role}</TableCell>
              <TableCell>
                {row.verified ? <CheckIcon /> : <CloseIcon />}
              </TableCell>
              <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Button
                  onClick={handleDelete(row.id)}
                  color='primary'
                  startIcon={<DeleteIcon />}
                >
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length === 0 && (
        <EmptyRowsMessage message={'There are no members in this community'} />
      )}
    </TableContainer>
  )
}

CommunityMembersTab.propTypes = {
  push: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
}

export default connect(null, {
  push,
})(CommunityMembersTab)
