import React from 'react'
import PropTypes from 'prop-types'
import { ArrayField, SingleFieldList, ChipField } from 'react-admin'
import { amenitiesToInput } from '../converter/RoomRequestConverter'

const AmenitiesList = (props) => {
  const record = props.record
  const amenities = record && record.amenities
  if (amenities && !Array.isArray(amenities)) {
    record.amenities = amenitiesToInput(amenities)
  }
  const newProps = { ...props, record }

  return (
    <ArrayField {...newProps} source={props.source}>
      <SingleFieldList>
        <ChipField size='small' source='en' />
      </SingleFieldList>
    </ArrayField>
  )
}

AmenitiesList.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
}

export default AmenitiesList
