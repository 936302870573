import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Chip,
  CircularProgress,
  TextField,
  FormLabel,
  withStyles,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import dataProvider from '../../dataProvider'
import { connect } from 'react-redux'
import { GET_LIST, showNotification } from 'react-admin'

const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 999,
    },
    select: {
      minWidth: theme.spacing(25),
    },
    emailContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    emailLabel: {
      fontSize: 12,
      margin: '8px 0px',
    },
    addButton: {
      margin: '16px 0px',
    },
    chip: {
      margin: '0px 8px',
    },
  }
}

const PersonSelect = ({
  classes,
  participants,
  onParticipantsChange,
  showNotification,
}) => {
  const [loading, setLoading] = React.useState(false)
  // the list of people
  const [peopleList, setPeopleList] = React.useState([])

  // email participant's field
  const [pendingParticipantEmail, setPendingParticipantEmail] = React.useState(
    '',
  )

  /**
   * Derived properties
   */
  const isEmail = (str) => str.toString().match('.+@.+')
  const emailParticipants = participants.filter(isEmail)
  const personToAutocompleteOption = (person) => ({
    title: `${person.firstName} ${person.lastName}`,
    id: person.personId,
  })

  const suggestedPeopleList = peopleList
    .filter((person) => !participants.includes(person.personId))
    .map(personToAutocompleteOption)

  const isPendingEmailAddedAlready = !!participants.find(
    (p) => p === pendingParticipantEmail,
  )

  /**
   * Given a new list of person ids, update the list of users
   * First keep only those email users
   * Then concat it with different list...
   */
  const addParticipantUser = (e, userList) => {
    onParticipantsChange([
      ...participants.filter((p) =>
        emailParticipants.find((emailPart) => emailPart === p),
      ),
      ...userList.map((user) => user.id),
    ])
  }
  const addParticipantEmail = () => {
    // same email is added already
    if (isPendingEmailAddedAlready) return

    onParticipantsChange([...participants, pendingParticipantEmail])
    // empty the field
    setPendingParticipantEmail('')
  }
  const removeParticipantEmail = (email) => {
    onParticipantsChange(participants.filter((p) => p !== email))
  }

  // fetch all people and store their first name last name
  React.useEffect(() => {
    dataProvider(GET_LIST, 'person/all')
      // setup the list of people
      .then((users) => {
        setPeopleList(users.data)
        return users.data
      })
      .catch((err) => showNotification(err.message, 'error'))
      .then(() => setLoading(false))
  }, [showNotification])

  return loading ? (
    <CircularProgress />
  ) : (
    <div className={classes.container}>
      <Autocomplete
        multiple
        loading={peopleList.length === 0}
        options={suggestedPeopleList}
        getOptionLabel={(opt) => opt.title}
        onChange={addParticipantUser}
        value={
          // to retrieve back the value, peopleList have to be populated first
          peopleList.length &&
          participants
            .filter((p) => !isEmail(p))
            .map((id) =>
              personToAutocompleteOption(
                peopleList.find(
                  (person) => person.personId.toString() === id.toString(),
                ),
              ),
            )
        }
        renderInput={(params) => (
          <TextField {...params} label='Participants (Existing users)' />
        )}
      />

      {/* Email options */}
      <FormLabel className={classes.emailLabel}>
        Participants (by email):{' '}
      </FormLabel>
      <div className={classes.emailContainer}>
        {emailParticipants.map((email) => (
          <Chip
            key={email}
            label={email}
            className={classes.chip}
            onDelete={() => removeParticipantEmail(email)}
          />
        ))}
      </div>
      <TextField
        name='email'
        type='text'
        label="Add a participant's email"
        value={pendingParticipantEmail}
        onChange={(e) => setPendingParticipantEmail(e.target.value)}
      />
      <Button
        className={classes.addButton}
        variant='contained'
        color='primary'
        disabled={
          pendingParticipantEmail === '' ||
          isPendingEmailAddedAlready ||
          !isEmail(pendingParticipantEmail)
        }
        onClick={addParticipantEmail}
      >
        Add participant
      </Button>
    </div>
  )
}

PersonSelect.propTypes = {
  participants: PropTypes.array.isRequired,
  onParticipantsChange: PropTypes.func.isRequired,
  showNotification: PropTypes.func,
  classes: PropTypes.object,
}

const StyledPersonSelect = withStyles(styles)(PersonSelect)

export default connect(null, {
  showNotification,
})(StyledPersonSelect)
