import { loadToken } from '../service/TokenService'

export const inviteMembers = (communityId, personIds = [], emails = []) => {
  return window.fetch(
    `${process.env.REACT_APP_WEB_API_V2_URL}/communities/${communityId}/invite`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: loadToken(),
      },
      method: 'POST',
      body: JSON.stringify({ personIds, emails }),
    },
  )
}
