import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
} from 'react-admin'
import AvailabilityInput from './fields/AvailabilityInput'
import ImageFieldWithSize from '../common/fields/ImageFieldWithSize'

const RoomEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm variant='standard' margin='normal'>
        <ImageFieldWithSize
          maxHeight={'12rem'}
          source='imageUrl'
          title='Photo'
        />
        <ImageInput
          source='image'
          label='Change image from above to below'
          accept='image/*'
        >
          <ImageField source='src' title='title' />
        </ImageInput>
        <TextInput source='roomId' />
        <TextInput source='name' />
        <NumberInput source='capacity' />
        <TextInput source='instructions.en' multiline />
        <TextInput source='instructions.ar' multiline />
        <TextInput source='details.en' multiline fullWidth />
        <TextInput source='details.ar' multiline fullWidth />
        <TextInput source='crestronId' />
        <AvailabilityInput source='availability' />
        <ArrayInput source='amenities'>
          <SimpleFormIterator>
            <TextInput source='en' label='Amenity' />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='amenities'>
          <SimpleFormIterator>
            <TextInput source='ar' label='Amenity' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default RoomEdit
