import { useEffect, useState, useRef } from 'react'
import debounce from 'lodash/debounce'
import { checkAvailability } from './bookingService'

function useBookingAvailability({ startTime, endTime }) {
  const [availableRooms, setAvailableRooms] = useState([])
  const [loading, setLoading] = useState(false)

  const debounceFetchAvailability = useRef(
    debounce((startTime, endTime) => {
      if (startTime && endTime) {
        setLoading(true)
        checkAvailability(startTime, endTime)
          .then((availableRooms) => {
            setAvailableRooms(availableRooms)
          })
          .finally(() => setLoading(false))
      }
    }, 1000),
  )

  useEffect(() => debounceFetchAvailability.current(startTime, endTime), [
    startTime,
    endTime,
  ])

  return [availableRooms, loading]
}

export default useBookingAvailability
