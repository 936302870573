import React from 'react'
import PropTypes from 'prop-types'
import { Filter, TextInput, SelectInput } from 'react-admin'

const roleChoices = [
  {
    id: 'member',
    name: 'Member',
  },
  {
    id: 'staff',
    name: 'Staff',
  },
  {
    id: 'admin',
    name: 'Admin',
  },
]

const statusChoices = [
  { id: true, name: 'Active' },
  { id: false, name: 'Disabled' },
]

const UserListFilter = ({ ...rest }) => (
  <Filter {...rest} variant='standard' margin='normal'>
    <TextInput label='Firstname' source='firstName' alwaysOn />
    <TextInput label='Lastname' source='lastName' alwaysOn />
    <TextInput label='Email Address' source='email' type='email' alwaysOn />
    <SelectInput
      label='Role'
      source='authRole'
      choices={roleChoices}
      alwaysOn
    />
    <SelectInput
      label='Status'
      source='accountEnabled'
      choices={statusChoices}
      alwaysOn
    />
  </Filter>
)

UserListFilter.propTypes = {
  roomGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
}

export default UserListFilter
export { roleChoices }
