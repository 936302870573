import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
} from 'react-admin'
import { languageChoices } from '../constants'
import Attachment from './Attachment'

const CommunityUpdateEdit = (props) => {
  return (
    <Edit {...props} title='Update community details'>
      <SimpleForm variant='standard' margin='normal'>
        <ImageInput
          source='image'
          label='Image'
          accept='image/*'
          multiple={false}
        >
          <ImageField source='src' title='title' />
        </ImageInput>
        <TextInput source='title' />
        <TextInput source='content' multiline fullWidth />
        <SelectInput source='language' choices={languageChoices} />
        <Attachment />
      </SimpleForm>
    </Edit>
  )
}

export default CommunityUpdateEdit
