import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  required,
  Toolbar,
  DeleteButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { languageChoices } from '../constants'
import Attachments from './Attachment'
import CommunityUpdateCreateButton from './CommunityUpdateCreateButton'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CommunityUpdateCreateToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <CommunityUpdateCreateButton {...props} />
    <DeleteButton />
  </Toolbar>
)

const CommunityUpdateCreate = (props) => (
  <Create {...props} title='Create Community Update'>
    <SimpleForm
      redirect='list'
      variant='standard'
      margin='normal'
      toolbar={<CommunityUpdateCreateToolbar />}
    >
      <ImageInput
        source='image'
        label='Image'
        accept='image/*'
        multiple={false}
      >
        <ImageField source='src' title='title' />
      </ImageInput>
      <TextInput source='title' validate={required()} />
      <TextInput source='content' validate={required()} multiline fullWidth />
      <SelectInput
        source='language'
        choices={languageChoices}
        validate={required()}
      />
      <ReferenceInput
        label='Community'
        source='communityId'
        reference='communities'
        validate={required()}
      >
        <SelectInput source='name' />
      </ReferenceInput>
      <Attachments />
    </SimpleForm>
  </Create>
)

export default CommunityUpdateCreate
