// components that offer inputs for recurrent event options
import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'

const styles = () => ({
  container: {
    marginTop: 8,
  },
})

const MeetingPurpose = ({ value, onPurposeChange, classes }) => {
  const onMeetingPurposeChange = (e) => {
    onPurposeChange(e)
  }

  return (
    <FormControl className={classes.container}>
      <FormLabel>Meeting Purpose</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={onMeetingPurposeChange}
        aria-label='meeting purpose'
        name='Meeting Purpose'
      >
        <FormControlLabel value='meeting' control={<Radio />} label='Meeting' />
        <FormControlLabel value='event' control={<Radio />} label='Event' />
      </RadioGroup>
    </FormControl>
  )
}
MeetingPurpose.propTypes = {
  value: PropTypes.string,
  onPurposeChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(MeetingPurpose)
