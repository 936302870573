import { parseISO, isValid, isBefore } from 'date-fns'

const isDateInFuture = (value) => {
  const date = parseDate(value)
  if (isValid(date) && isBefore(date, new Date())) {
    return 'Cannot be in past'
  }
  return ''
}

const isValidDate = (value) => {
  if (isValid(parseDate(value))) {
    return ''
  }
  return 'Invalid date!'
}

const parseDate = (value) => {
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export { isDateInFuture, isValidDate }
