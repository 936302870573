import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  showNotification,
  CREATE,
  GET_LIST,
  AutocompleteArrayInput,
} from 'react-admin'
import { connect } from 'react-redux'
import { Button, TextField } from '@material-ui/core'
import dataProvider from '../../dataProvider'

const endpoints = {
  community: 'tags/community',
  community_event: 'tags/communityEvent',
}

const TagsInput = ({ showNotification, source, tagType, ...props }) => {
  const [tag, setTag] = useState('')
  const [tags, setTags] = useState([])

  useEffect(() => {
    dataProvider(GET_LIST, endpoints[tagType]).then((res) => {
      // The data Provider uses this during createCommunityEvent
      window.tags = res.data
      setTags(res.data)
    })
  }, [tagType])

  const handleCreate = () => {
    if (!tag) {
      return
    }

    const existingTag = tags.find((item) => item.tag === tag)
    if (existingTag) {
      showNotification('This tag already exists', 'error')
      return
    }

    dataProvider(CREATE, 'tags', { data: { tag, tagType } })
      .then((res) => {
        showNotification('Tag has been successfully created')
        setTags([...tags, res.data])
        setTag('')
      })
      .catch((err) => {
        if (err) {
          showNotification("Couldn't create a tag", 'error')
        }
      })
  }

  const handleChangeTag = (e) => {
    setTag(e.target.value)
  }

  return (
    <>
      <AutocompleteArrayInput
        {...props}
        label='Tags'
        source={source}
        choices={tags}
      />
      <div>
        <TextField
          label=''
          value={tag}
          onChange={handleChangeTag}
          placeholder='New tag'
        />
        <Button onClick={handleCreate}>Add</Button>
      </div>
    </>
  )
}

TagsInput.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  tagType: PropTypes.oneOf(['community', 'community_event']),
  showNotification: PropTypes.func.isRequired,
}

export default connect(null, {
  showNotification,
})(TagsInput)
