import React, { useEffect, useState } from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  makeStyles,
  Button,
} from '@material-ui/core'
import { DELETE, GET_LIST } from 'ra-core'
import dataProvider from '../../dataProvider'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import EmptyRowsMessage from '../../common/EmptyRowsMessage'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  row: {
    cursor: 'pointer',
  },
})

const CommunityUpdateTab = ({ push, record, id }) => {
  const classes = useStyles()

  const [communityUpdates, setCommunityUpdates] = useState([])
  useEffect(() => {
    getCommunityUpdates()
  }, [])

  const getCommunityUpdates = () => {
    dataProvider(GET_LIST, 'communityUpdates', {
      filter: { community: { id } },
    }).then((response) => {
      setCommunityUpdates(response.data)
    })
  }

  const createData = ({ id, title, description, createdAt, createdBy }) => {
    return { id, title, description, createdAt, createdBy: createdBy.email }
  }

  const handleDelete = (id) => (e) => {
    e.stopPropagation()
    dataProvider(DELETE, 'communityUpdates', {
      data: { id, community: { id: communityId } },
    }).then(() => getCommunityUpdates())
  }

  const { id: communityId } = record
  const rows = communityUpdates
    .filter((update) => update.community.id === communityId)
    .map((update) => createData(update))

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              className={classes.row}
              hover
              onClick={() =>
                push(`/communityUpdates/${row.id}?communityId=${communityId}`)
              }
              key={row.title}
            >
              <TableCell component='th' scope='row'>
                {row.title}
              </TableCell>
              <TableCell>{row.createdBy}</TableCell>
              <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Button
                  onClick={handleDelete(row.id)}
                  color='primary'
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length === 0 && (
        <EmptyRowsMessage message={'There are no updates for this community'} />
      )}
    </TableContainer>
  )
}

CommunityUpdateTab.propTypes = {
  push: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
}

export default connect(null, {
  push,
})(CommunityUpdateTab)
