import React, { Fragment, useState } from 'react'
import { List, Datagrid, TextField, DateField, EmailField } from 'react-admin'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ActionButton from './fields/ActionButton'
import UnreadSelectField from './fields/UnreadSelectField'
import MarkReadButton from './fields/MarkReadButton'

const tabs = [
  { id: 'nonArchived', name: 'New Reports' },
  { id: 'archived', name: 'Archived Reports' },
]

const reportTypeChoices = [
  { id: 'profile', name: 'Profile' },
  { id: 'chat', name: 'Chat' },
  { id: 'organization', name: 'Organization' },
  { id: 'event', name: 'Event' },
  { id: 'project', name: 'Project' },
  { id: 'projectUpdate', name: 'Project Update' },
  { id: 'community', name: 'Community' },
  { id: 'communityEvents', name: 'Community Events' },
  { id: 'communityUpdates', name: 'Community Updates' },
]

const ReportList = (props) => {
  const [filters, setFilters] = useState({
    archived: false,
  })
  const [activeTab, setActiveTab] = useState('nonArchived')

  const handleChange = (event, value) => {
    setActiveTab(value)
    setFilters({ archived: value === 'archived' })
  }

  return (
    <List
      {...props}
      bulkActionButtons={<MarkReadButton />}
      filterDefaultValues={filters}
    >
      <>
        <Tabs
          fullWidth
          value={activeTab}
          indicatorColor='primary'
          onChange={handleChange}
          style={{ marginBottom: 10 }}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Datagrid
          sort={{ field: 'createdAt', order: 'DESC' }}
          title='Reports'
          perPage={15}
        >
          <UnreadSelectField
            source='reportType'
            choices={reportTypeChoices}
            bulkActionButtons={false}
          />
          <TextField source='title' label='Reporter' />
          <EmailField source='reporterEmail' />
          <TextField source='reason' />
          <DateField source='createdAt' />
          <ActionButton />
        </Datagrid>
      </>
    </List>
  )
}

export default ReportList
