import React, { useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import AttachmentLinks from './AttachmentLinks'
import AttachmentImages from './AttachmenImages'
import {
  deleteAttachment,
  fileUpload,
  loadUpdate,
  saveAttachmentLink,
} from './fileAttachmentService'
import AttachmentFiles from './AttachmenFiles'

const Attachments = ({ record }) => {
  const fileInput = useRef()
  const updateId = useRef()
  const form = useForm()
  const formState = useFormState()
  const [pendingFiles, setPendingFiles] = useState([])
  const [attachments, setAttachments] = useState('')
  // only save the id, if it already is available
  if (record.id) {
    updateId.current = record.id
  }
  const communityId = document.location.hash.split('=')[1]

  const reloadAttachments = () => {
    loadUpdate(communityId, updateId.current).then((response) => {
      setAttachments(response.attachments)
    })
  }

  const onDelete = (attachmentId, index) => {
    if (!record.id) {
      formState.values.links.splice(index, 1)
      form.change('links', [...formState.values.links])
      return
    }

    deleteAttachment(communityId, updateId.current, attachmentId).then(
      reloadAttachments,
    )
  }

  const saveLink = (linkText) => {
    if (!record.id) {
      form.change('links', (formState.values.links || []).concat(linkText))
      return
    }

    saveAttachmentLink(communityId, updateId.current, linkText).then(() => {
      reloadAttachments()
    })
  }

  const fileChange = () => {
    const files = fileInput.current && fileInput.current.files
    const newFiles = [...pendingFiles, ...files]
    setPendingFiles(newFiles)
    form.change('attachments', newFiles)
  }

  const deletePendingFile = (i) => {
    pendingFiles.splice(i, 1)
    setPendingFiles([...pendingFiles])
    form.change('attachments', [...pendingFiles])
  }

  const upload = (file, i) => {
    fileUpload(communityId, updateId.current, file).then(() => {
      reloadAttachments()
      deletePendingFile(i)
    })
  }
  /*
    this is how attachments look like
      0: {id: 42, fileName: null, cloudinaryPublicId: null, mimetype: null, resourceType: "link", …}
      1: {id: 44, fileName: "air1.png", cloudinaryPublicId: "cnxxxkeoopimxghcnekb", mimetype: "image/png", resourceType: "image", …}
      ...
  */

  if (!record) {
    return null
  }

  // initially state attachment is empty and we work with
  // the record of the edit page.
  // if attachments are uploaded we reload them and put them
  // into the state. So if there is a state it is newer
  const att = attachments || record.attachments || []
  const links = att.filter((d) => d.resourceType === 'link')
  const images = att.filter((d) => d.resourceType === 'image')
  const files = att.filter(
    (f) => f.resourceType !== 'link' && f.resourceType !== 'image',
  )

  return (
    <div>
      <AttachmentLinks
        links={record.id ? links : formState.values.links}
        onAdd={saveLink}
        onDelete={onDelete}
      />

      {record.id && (
        <>
          <AttachmentImages images={images} onDelete={onDelete} />
          <AttachmentFiles onDelete={onDelete} files={files} />
        </>
      )}

      <div>
        <Typography>Add a file or image Attachment: </Typography>
        <input
          style={{ display: 'none' }}
          onChange={fileChange}
          ref={(ref) => (fileInput.current = ref)}
          type='file'
          id='raised-button-file'
          name='myFile'
          multiple
        />
        <label htmlFor='raised-button-file'>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<CloudUploadIcon />}
            component='span'
          >
            Choose files
          </Button>
        </label>
        <br />
        <br />
      </div>

      {pendingFiles.map((f, i) => (
        <div key={i}>
          <span>{f.name}</span>
          {record.id && (
            <Button color='primary' onClick={() => upload(f)}>
              Save
            </Button>
          )}
          <Button onClick={() => deletePendingFile(i)}>Delete</Button>
        </div>
      ))}

      <br />
    </div>
  )
}

Attachments.propTypes = {
  record: PropTypes.object,
  push: PropTypes.func,
}

export default connect(null, { push })(Attachments)
