import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { useNotify, useMutation } from 'react-admin'

const MarkReadButton = ({ selectedIds }) => {
  const notify = useNotify()

  const [mutate] = useMutation()
  const approve = async () => {
    try {
      await Promise.all(
        selectedIds.map(
          async (id) =>
            await mutate({
              type: 'update',
              resource: 'report',
              payload: {
                id,
                data: { reviewStatus: 'reviewed' /*, updatedAt: new Date()*/ },
              },
            }),
        ),
      )
    } catch (e) {
      notify(`Error: Reports could not be updated`, 'warning')
    }
    notify(`Reports updated`, 'info')
  }

  const handleClick = (event) => {
    event.stopPropagation()
    approve()
  }

  return (
    <Button onClick={handleClick} variant='contained' color='primary'>
      Mark as Reviewed
    </Button>
  )
}
MarkReadButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
}

export default MarkReadButton
