import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin'
import { privacyChoices } from './constants'
import TagsInput from './fields/TagsInput'

const CommunityCreate = (props) => (
  <Create {...props}>
    <SimpleForm variant='standard' margin='normal'>
      <ImageInput source='image' label='Image' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>
      <TextInput source='name' validate={required()} />
      <TextInput source='location' validate={required()} />
      <TextInput source='description' validate={required()} />
      <SelectInput
        source='privacy'
        choices={privacyChoices}
        validate={required()}
      />
      <TagsInput source='tags' tagType='community' />
    </SimpleForm>
  </Create>
)

export default CommunityCreate
