import React from 'react'
import {
  TextInput,
  TabbedForm,
  FormTab,
  Edit,
  Toolbar,
  DeleteButton,
} from 'react-admin'
import RoomArray from './fields/RoomArray'
import MemberArray from './fields/MemberArray'
import PolicySelect from './PolicySelect'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import RoomGroupSaveButton from './RoomGroupSaveButton'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const RoomGroupToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <RoomGroupSaveButton {...props} />
    <DeleteButton />
  </Toolbar>
)

const RoomGroupEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm
        variant='standard'
        redirect='list'
        margin='normal'
        toolbar={<RoomGroupToolbar />}
      >
        <FormTab label='Name/description'>
          <TextInput source='name' />
          <TextInput source='description' multiline />
        </FormTab>

        <FormTab label='Policy'>
          <PolicySelect />
        </FormTab>

        <FormTab label='Rooms/Members'>
          <RoomArray />
          <MemberArray />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

RoomGroupEdit.propTypes = {
  record: PropTypes.object,
}

export default RoomGroupEdit
