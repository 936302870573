import cloneDeep from 'lodash/cloneDeep'

export const createCommunityUpdate = (payload) => {
  const data = cloneDeep(payload)

  const form = new window.FormData()
  form.append('title', data.title)
  form.append('content', data.content)
  form.append('language', data.language)
  data.image && form.append('image', data.image.rawFile)
  data.communityId && form.append('image', data.communityId)

  return form
}

export const communityUpdateGetOne = (dataOriginal) => {
  const data = cloneDeep(dataOriginal)
  const attachments = data.attachments
  const links = attachments.filter((d) => d.resourceType === 'link')
  const images = attachments.filter((d) => d.resourceType === 'image')
  data.links = links
  data.images = images
  return { data }
}
