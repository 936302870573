import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Select from 'react-select'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      minWidth: 200,
      width: '100%',
      maxWidth: 1000,
    },
    button: {
      marginLeft: 10,
      width: 120,
    },
  }),
)

const CalendarRoomSelector = ({ options, value, onChange }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Select
        placeholder='Select rooms...'
        className={classes.select}
        options={options.map((room) => ({
          value: room.meetingRoomId,
          label: room.name,
        }))}
        onChange={onChange}
        closeMenuOnSelect={false}
        value={value}
        isMulti
      />
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        onClick={() =>
          onChange(
            options.map((room) => ({
              value: room.meetingRoomId,
              label: room.name,
            })),
          )
        }
      >
        Add All
      </Button>
    </div>
  )
}

CalendarRoomSelector.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
}

export default CalendarRoomSelector
