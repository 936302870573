import React, { useEffect, useState, useMemo } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  Button,
  withStyles,
  TextField,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'

import useBookingAvailability from '../useBookingAvailability'
import moment from 'moment-timezone'
import ParticipantsSelect from './ParticipantsSelect'
import useBookings from '../useBookings'
import MeetingPurpose from './MeetingPurpose'

const styles = () => ({
  infoGroup: {
    marginTop: '0.5rem',
  },
  label: {
    // interestingly the "1 rem" here is not equivalent to the "1rem" from the default MUI theme
    // after some calibrations its set to 12 (px)
    fontSize: 12,
  },
})

const EventDetails = ({
  showEvent,
  event,
  rooms,
  classes,
  onClose,
  onDelete,
  onChange,
}) => {
  /***********************************************
   * Hooks
   **********************************************/
  const { fetchOneBooking } = useBookings()
  /***********************************************
   * Form values
   **********************************************/
  // flag to indicate if the "delete" action from the confirmation popup is actually deleting all recurrent events
  const [isDeletingRecurrentEvents, setIsDeletingRecurrentEvents] = useState(
    false,
  )
  const [isConfirmationDialog, setConfirmationDialog] = useState(false)
  const [subject, setSubject] = useState(event.subject)
  const [newRoomId, setNewRoomId] = useState(event.roomId)
  const [meetingPurpose, setMeetingPurpose] = useState(event.purpose)

  // meta flag to indicate whether participants list is loading
  const [loadingParticipants, setLoadingParticipants] = useState(false)

  // list of PERSONID of participants
  // original participants means those that are there "before" the modifications made in this form
  const [originalParticipants, setOriginalParticipants] = useState([])
  // and this one means those that are used by this form. This one would be the final list of participants
  const [participants, setParticipants] = useState([])

  const [startTime, setStartTime] = useState(moment(event.startTime))
  const [endTime, setEndTime] = useState(moment(event.endTime))

  const [fetchedAvailableRooms] = useBookingAvailability({ startTime, endTime })
  const availableRooms = useMemo(() => {
    const previouslySelectedRoom = rooms.find(
      (r) => r.meetingRoomId === newRoomId,
    )
    return fetchedAvailableRooms.concat(previouslySelectedRoom)
  }, [rooms, newRoomId, fetchedAvailableRooms])

  const openConfirmation = () => {
    setConfirmationDialog(true)
  }

  /**
   * Load the list of participants whenever event changes
   */
  useEffect(() => {
    const fetchParticipants = async () => {
      setLoadingParticipants(true)
      const eventDetails = await fetchOneBooking(event.bookingId)
      const participants =
        eventDetails?.participants?.map(
          (participant) =>
            // either its a recognized person, or it is a email...
            participant.person?.personId.toString() ||
            participant.email.toString(),
        ) || []
      setLoadingParticipants(false)
      setOriginalParticipants(participants)
      setParticipants(participants)
    }

    fetchParticipants()

    // fetchOneBooking will NOT change. Therefore not in dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const openConfirmationForDeletingRecurrentEvents = () => {
    setIsDeletingRecurrentEvents(true)
    setConfirmationDialog(true)
  }

  function handleDelete() {
    onDelete(event, isDeletingRecurrentEvents)
    onClose()
  }

  function handleChange() {
    onChange({
      event: {
        ...event,
        // updating the event requires the list of participants before change to proceed
        participants: originalParticipants,
      },
      subject,
      roomId: newRoomId,
      start: startTime,
      end: endTime,
      purpose: meetingPurpose,
      participants,
    })
    onClose()
  }

  return (
    <Dialog
      fullWidth={!isConfirmationDialog}
      open={showEvent}
      onClose={onClose}
    >
      {isConfirmationDialog ? (
        <>
          <DialogTitle>
            Are you sure you want to cancel this booking?
          </DialogTitle>
          <DialogActions>
            <Button onClick={onClose} color='default'>
              No
            </Button>
            <Button
              onClick={handleDelete}
              color='primary'
              variant='contained'
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Event details</DialogTitle>
          <DialogContent>
            <div className={classes.infoGroup}>
              <FormLabel classes={{ root: classes.label }}>Subject</FormLabel>
              <Typography variant='body2'>
                <TextField
                  label=''
                  value={subject}
                  onChange={(ev) => setSubject(ev.target.value)}
                />
              </Typography>
            </div>
            <div className={classes.infoGroup}>
              <FormLabel classes={{ root: classes.label }}>Booked By</FormLabel>
              <Typography variant='body1'>{event.bookerEmail}</Typography>
            </div>
            <div className={classes.infoGroup}>
              <TimePicker
                style={{ width: '100%' }}
                name='startTime'
                label='Start Time'
                minutesStep={5}
                ampm={false}
                value={startTime}
                maxDate={endTime}
                onChange={setStartTime}
                required
                autoOk
              />
            </div>

            <div className={classes.infoGroup}>
              <TimePicker
                style={{ width: '100%' }}
                name='endTime'
                label='End Time'
                minutesStep={5}
                ampm={false}
                minDate={startTime}
                value={endTime}
                onChange={setEndTime}
                required
                autoOk
              />
            </div>
            <FormControl className={classes.infoGroup} fullWidth>
              <InputLabel htmlFor='room-select'>Room</InputLabel>
              <Select
                labelId='room-select'
                id='room-select'
                value={newRoomId}
                onChange={(ev) => setNewRoomId(ev.target.value)}
              >
                {availableRooms.map((room) => (
                  <MenuItem key={room.meetingRoomId} value={room.meetingRoomId}>
                    {room.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MeetingPurpose
              value={meetingPurpose}
              onPurposeChange={(e) => setMeetingPurpose(e.target.value)}
            />

            <div className={classes.infoGroup}>
              {loadingParticipants ? (
                <CircularProgress />
              ) : (
                <ParticipantsSelect
                  participants={participants}
                  onParticipantsChange={setParticipants}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={openConfirmation} color='default'>
              Cancel booking
            </Button>
            {event.type === 'recurring' && (
              <Button
                onClick={openConfirmationForDeletingRecurrentEvents}
                color='default'
              >
                Cancel all recurring bookings
              </Button>
            )}
            <Button onClick={handleChange} variant='contained' color='primary'>
              OK
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

EventDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showEvent: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  rooms: PropTypes.array,
  classes: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
}

export default withStyles(styles)(EventDetails)
