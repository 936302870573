import React from 'react'
import { SelectField } from 'react-admin'
import { makeStyles, createStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() =>
  createStyles({
    selectField: {
      fontFamily: (props) => (props.unread ? 'Avenir-Medium' : 'Avenir-Book'),
      fontWeight: 'bolder',
    },
  }),
)

const UnreadSelectField = ({ record, ...props }) => {
  const classes = useStyles({ unread: record.reviewStatus === 'unreviewed' })
  return (
    <SelectField className={classes.selectField} record={record} {...props} />
  )
}

UnreadSelectField.propTypes = {
  record: PropTypes.object,
}

export default UnreadSelectField
