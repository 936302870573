import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import {
  loadToken,
  logout,
  storeToken,
  setRole,
  loadRole,
  setPersonId,
} from './service/TokenService'
import { AUTH_GET_PERMISSIONS } from 'ra-core'

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { sessionToken } = params
    return fetch(`${process.env.REACT_APP_WEB_API_V1_URL}/person/me`, {
      method: 'GET',
      headers: new Headers({ Authorization: sessionToken }),
    })
      .then((response) => response.json())
      .then((json) => {
        setRole(json.authRole)
        setPersonId(json.personId)
        storeToken(sessionToken)
      })
  }
  if (type === AUTH_LOGOUT) {
    logout()
    return Promise.resolve()
  }
  if (type === AUTH_ERROR) {
    const { status } = params.response
    if (status === 401 || status === 403) {
      logout()
      return Promise.reject(new Error('Unauthorized.'))
    } else if (status === 500) {
      return Promise.reject(new Error('Server error'))
    }
    return Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    return loadToken()
      ? Promise.resolve()
      : Promise.reject(new Error('Unauthorized'))
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = loadRole()
    return role
      ? Promise.resolve(role)
      : Promise.reject(new Error('Role not set'))
  }
  return Promise.reject(new Error('Unkown method'))
}
