import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import CalendarToolbar from './CalendarToolbar'
import BookingCreate from './BookingCreate'
import BookingEdit from './BookingEdit'
import ConflictForm from './ConflictForm'
import { CircularProgress, useTheme, withStyles } from '@material-ui/core'
import colors from '../colors'
import useBookings from '../useBookings'

const DragAndDropCalendar = withDragAndDrop(Calendar)

// moment.tz.setDefault('Asia/Dubai')
const localizer = momentLocalizer(moment)

const styles = () => ({
  root: {
    position: 'relative',
  },
  loadingIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  calendar: {
    '& .rbc-timeslot-group': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
})

const EventCalendar = ({
  rooms,
  roomIds,
  user,
  classes: { loadingIcon, root, calendar },
}) => {
  /**
   * storing an object for marking down there is a booking conflict
   * The object should have the form:
   *  {
   *      booking: <...>
   *      impossibleBookings: [{startTime: Date, endTime: Date, ...}]
   *   }
   */
  const [pendingBookingConflict, setPendingBookingConflict] = useState(null)
  const [openCreateBooking, setOpenCreateBooking] = useState(false)
  const [openEditBooking, setOpenEditBooking] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState({})
  const initStartEndDates = useMemo(
    () => ({
      startTime: moment().startOf('w').toISOString(),
      endTime: moment().endOf('w').toISOString(),
    }),
    [],
  )
  const [dates, setDates] = useState(initStartEndDates)
  const [selectedSlot, setSelectedSlot] = useState({})

  // flag indicate the process of booking the recurrent booking by ignoring conflicting timeslots
  const [resolvingAllConficts, setResolvingAllConficts] = useState(false)

  const {
    bookings,
    isLoading,
    fetchBookings,
    deleteBooking,
    updateBooking,
    createBooking: rawCreateBooking,
  } = useBookings({
    shouldConvert: true,
    roomIds,
    ...dates,
  })

  const theme = useTheme()

  useEffect(() => {
    fetchBookings()
  }, [fetchBookings])

  const onSelectSlot = (slotInfo) => {
    setSelectedEvent(slotInfo.event)
    setSelectedSlot({
      selectedStartTime: moment(slotInfo.start),
      selectedEndTime: moment(slotInfo.end),
    })
    setOpenCreateBooking(true)
  }

  const onSelectEvent = (event) => {
    setSelectedEvent(event)
    setOpenEditBooking(true)
  }

  const createEventClassName = (event) => {
    const chosenColor = colors[event.roomId % colors.length]
    let className = `room-${event.roomId}`
    className += moment(event.endTime).isBefore() ? ' rbc-past-event' : ''
    return {
      className,
      style: {
        background: chosenColor,
        color: theme.palette.getContrastText(chosenColor),
      },
    }
  }

  const onRangeChange = (dates) => {
    const isMonth = !Array.isArray(dates)
    const isWeek = !isMonth && !!dates[6]

    let startDate, endDate
    if (isMonth) {
      startDate = dates.start
      endDate = dates.end
    } else if (isWeek) {
      startDate = dates[0]
      endDate = dates[6]
    } else {
      // day view
      startDate = dates[0]
      endDate = moment(dates[0]).endOf('day')
    }

    const startTime = moment(startDate).toISOString()
    const endTime = moment(endDate).toISOString()

    setDates({ startTime, endTime })
  }

  /***
   * Logic for processing the booking request
   * Since there may be multiple rooms that the users want to book,
   * each of the room ids will be mapped with the "rawCreateBooking" function (which assumes only booking one room)
   * and results, mainly impossible bookings,
   * will be aggregated and be shown in conflicting booking modal
   */
  const createBooking = async (bookings, ignoreConflictingBookings = false) => {
    const { roomIds, ...bookingParams } = bookings
    const impossibleBookingsInAllRooms = await Promise.all(
      roomIds.map((id) =>
        rawCreateBooking(
          {
            roomId: id,
            ...bookingParams,
          },
          ignoreConflictingBookings,
        ),
      ),
    )
    // aggregate all returns (which should be a list of "impossible bookings")
    const impossibleBookingList = impossibleBookingsInAllRooms.reduce(
      (res, impossibleBookings, index) => [
        ...res,
        // for each of the impossible bookings, add the roomId into it...
        ...(impossibleBookings || []).map((impBooking) => ({
          ...impBooking,
          // add the room Id to this conflict entry
          // ASSUMING that the results from this list is having the same order as the promise above
          // use the index to get back the ID of the room of this conflict...
          roomId: roomIds[index],
        })),
      ],
      [], // give initial value to avoid crashing if there are no conflicts
    )

    return impossibleBookingList.length > 0 ? impossibleBookingList : null
  }

  const filteredBookings = useMemo(
    () => bookings.filter((e) => roomIds.includes(e.roomId)),
    [bookings, roomIds],
  )

  return (
    <div className={root}>
      {isLoading && <CircularProgress className={loadingIcon} />}
      <DragAndDropCalendar
        localizer={localizer}
        events={filteredBookings}
        eventPropGetter={createEventClassName}
        startAccessor='startTime'
        endAccessor='endTime'
        titleAccessor='subject'
        views={[Views.MONTH, Views.WEEK, Views.DAY]}
        defaultView={Views.WEEK}
        selectable
        step={15}
        timeslots={4}
        onEventDrop={updateBooking}
        resizable
        onEventResize={updateBooking}
        scrollToTime={moment().subtract(2, 'h').toDate()}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        onRangeChange={onRangeChange}
        components={{ toolbar: CalendarToolbar }}
        className={calendar}
      />
      {openCreateBooking && (
        <BookingCreate
          {...selectedSlot}
          user={user}
          roomIds={roomIds}
          openDialog={openCreateBooking}
          onClose={() => setOpenCreateBooking(false)}
          onCreateBookRoom={async (booking) => {
            const impossibleBookings = await createBooking(booking)
            if (impossibleBookings?.length > 0) {
              setPendingBookingConflict({ impossibleBookings, booking })
            }
            setOpenCreateBooking(false)
          }}
        />
      )}
      {openEditBooking && (
        <BookingEdit
          event={selectedEvent}
          rooms={rooms}
          showEvent={openEditBooking}
          onClose={() => setOpenEditBooking(false)}
          onChange={updateBooking}
          onDelete={deleteBooking}
        />
      )}
      {pendingBookingConflict && (
        <ConflictForm
          pendingBookingConflict={pendingBookingConflict}
          resolvingAllConficts={resolvingAllConficts}
          onAllCoflictsResolved={async () => {
            setResolvingAllConficts(true)
            // submit the bookings again, except this time ignore all conficts
            // (they are supposed to be resolved when this line is reached)
            await createBooking(pendingBookingConflict.booking, true)
            // then clear the conflict
            setPendingBookingConflict(null)
            setResolvingAllConficts(false)
          }}
        />
      )}
    </div>
  )
}

EventCalendar.propTypes = {
  roomIds: PropTypes.arrayOf(PropTypes.number),
  allRoomIds: PropTypes.arrayOf(PropTypes.number),
  user: PropTypes.object,
  rooms: PropTypes.array,
  classes: PropTypes.object,
}

export default withStyles(styles)(EventCalendar)
