import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Filter,
  TextInput,
  SelectField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'

import CommunityVerifySwitch from './fields/CommunityVerifySwitch'
import { privacyChoices } from './constants'

const useStyles = makeStyles({
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
  },
  name: {
    width: 200,
  },
})
const CommunityFilter = (props) => (
  <Filter {...props} variant='standard' margin='normal'>
    <TextInput label='Name' source='name' alwaysOn />
    <TextInput label='Location' source='location' alwaysOn />
    <TextInput label='Email' source='createdBy[email]' alwaysOn />
  </Filter>
)

const CommunityList = (props) => {
  const classes = useStyles()
  return (
    <List
      {...props}
      filters={<CommunityFilter />}
      sort={{ field: 'totalMembers', order: 'DESC' }}
      perPage={15}
    >
      <Datagrid rowClick='edit'>
        <TextField
          className={`${classes.description} ${classes.name}`}
          source='name'
        />
        <TextField className={classes.description} source='description' />
        <NumberField source='totalMembers' align='left' label='Members' />
        <SelectField source='privacy' choices={privacyChoices} />
        <CommunityVerifySwitch label='Verified' />
      </Datagrid>
    </List>
  )
}

export default CommunityList
