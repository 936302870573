import cloneDeep from 'lodash/cloneDeep'

export const communityGetOne = (dataOriginal) => {
  const data = cloneDeep(dataOriginal)
  data.tags = data.tags && data.tags.map((t) => t.tagId)
  return { data }
}

export const createCommunity = (payload) => {
  const data = cloneDeep(payload)

  const form = new window.FormData()
  form.append('name', data.name)
  form.append('location', data.location)
  form.append('description', data.description)
  form.append('privacy', data.privacy)
  data.image && form.append('image', data.image.rawFile)

  data.tags = data.tags.map((id) => window.tags.find((t) => t.tagId === id))
  form.append('tags', JSON.stringify(data.tags))

  return form
}
