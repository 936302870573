import React from 'react'
import MeetingRoomIcon from '@material-ui/icons/EventSeat'
import RoomsList from './RoomsList'
import RoomEdit from './RoomEdit'
import RoomCreate from './RoomCreate'

const list = (props) => <RoomsList {...props} />
const edit = (props) => <RoomEdit {...props} />
const create = (props) => <RoomCreate {...props} />
const icon = () => <MeetingRoomIcon />

const Rooms = {
  list,
  edit,
  create,
  icon,
}

export default Rooms
