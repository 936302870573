import React from 'react'
import PropTypes from 'prop-types'
import {
  IconButton,
  Menu,
  ListItemText,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { showNotification } from 'react-admin'
import dataProvider from '../../dataProvider'
import useRefresh from '../../common/useRefresh'
import { PUT_UPDATE } from '../../common/requestTypes'

const useStyles = makeStyles({
  button: {
    float: 'right',
  },
  noActionsRow: {
    padding: '24px 0',
  },
})

const reportInformation = (reportType) => {
  switch (reportType) {
    case 'communityUpdates':
      return {
        buttonTitle: 'Hide Community Update',
        successNotification: 'Community update has been hidden successfully',
        errorNotification: 'Error while hiding community update',
      }
    case 'community':
      return {
        buttonTitle: 'Hide Community',
        successNotification: 'Community has been hidden successfully',
        errorNotification: 'Error while hiding community',
      }
    case 'profile':
      return {
        buttonTitle: 'Disable User',
        successNotification: 'Profile has been disabled successfully',
        errorNotification: 'Error while disabling profile',
      }
    case 'chat':
      return {
        buttonTitle: 'Disable users',
        successNotification: 'Users have been disabled successfully',
        errorNotification: 'Error while disabling users',
      }
    default:
      return {
        buttonTitle: 'Take Action',
        successNotification: 'Report action has successfully been executed',
        errorNotification: 'Error while executing report action',
      }
  }
}

const ActionButton = ({ record }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const refresh = useRefresh()
  const {
    buttonTitle,
    successNotification,
    errorNotification,
  } = reportInformation(record.reportType)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event?.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const takeReportAction = () => {
    dataProvider(PUT_UPDATE, `report/${record.reportId}/status`, {
      reviewStatus: 'hidden',
    })
      .then(() => {
        showNotification(successNotification)
        refresh()
      })
      .catch((err) => {
        if (err) {
          showNotification(errorNotification, 'error')
        }
      })
  }

  const markAsReviewed = () => {
    dataProvider(PUT_UPDATE, `report/${record.reportId}/status`, {
      reviewStatus: 'ignored',
    })
      .then(() => {
        showNotification('Report has been marked as reviewed')
        refresh()
      })
      .catch((err) => {
        if (err) {
          showNotification('Report could not be marked as reviewed', 'error')
        }
      })
  }

  if (record.reviewStatus === 'hidden') {
    return <div className={classes.noActionsRow} />
  }

  return (
    <div>
      <IconButton
        className={classes.button}
        aria-label='more'
        aria-controls='action-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='action-menu'
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {record.reviewStatus !== 'hidden' && (
          <MenuItem onClick={takeReportAction}>
            <ListItemText>{buttonTitle}</ListItemText>
          </MenuItem>
        )}
        {record.reviewStatus === 'unreviewed' && (
          <MenuItem onClick={markAsReviewed}>
            <ListItemText>Mark as Reviewed</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
ActionButton.propTypes = {
  record: PropTypes.object,
}

export default ActionButton
