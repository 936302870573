import { loadToken } from '../../service/TokenService'

const getAttachmentsUrl = (communityId, updateId) => {
  const apiUrl = process.env.REACT_APP_WEB_API_V2_URL
  const endPoint = `communities/${communityId}/updates/${updateId}/attachments`
  return `${apiUrl}/${endPoint}`
}

const getHeader = () => ({
  Authorization: loadToken(),
})

export const fileUpload = (communityId, updateId, file) => {
  if (!file) {
    alert('no file selected')
    return
  }
  const formData = new FormData()
  formData.append('attachments', file)
  formData.append('communityId', communityId)
  formData.append('updateId', updateId)

  return window.fetch(getAttachmentsUrl(communityId, updateId), {
    method: 'POST',
    headers: getHeader(),
    body: formData,
  })
}

export const saveAttachmentLink = (communityId, updateId, link) => {
  const formData = new FormData()
  formData.append('communityId', communityId)
  formData.append('updateId', updateId)
  formData.append('links', link)
  return window.fetch(getAttachmentsUrl(communityId, updateId), {
    headers: getHeader(),
    method: 'POST',
    body: formData,
  })
}

export const deleteAttachment = (communityId, updateId, attachmentId) => {
  return window.fetch(
    getAttachmentsUrl(communityId, updateId) + `/${attachmentId}`,
    {
      headers: getHeader(),
      method: 'DELETE',
    },
  )
}

export const loadUpdate = (communityId, updateId) => {
  const apiUrl = process.env.REACT_APP_WEB_API_V2_URL
  const endPoint = `communities/${communityId}/updates/${updateId}`

  return window
    .fetch(`${apiUrl}/${endPoint}`, {
      headers: getHeader(),
      method: 'GET',
    })
    .then((res) => res.json())
}
