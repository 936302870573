import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chip, withStyles } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { addMember, getMembers, removeMember } from '../roomGroupService'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  chips: {
    marginTop: theme.spacing(2),
  },
})

class MemberArray extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: true,
      members: [],
      selectedMembers: props.record.groupMembers || [],
      filter: '',
    }
  }

  componentDidMount() {
    getMembers().then((response) => {
      this.setState({
        members: response.data,
      })
    })
  }

  onDelete = (group, member) => {
    removeMember(group.id, member.personId).then(() =>
      this.setState((state) => ({
        selectedMembers: state.selectedMembers.filter(
          (r) => r.personId !== member.personId,
        ),
      })),
    )
  }

  onAdd = (group, member) => {
    if (!member) {
      return
    }

    addMember(group.id, member.personId).then(() => {
      this.setState((state) => ({
        selectedMembers: [...state.selectedMembers, member],
        isVisible: false,
      }))
      window.setTimeout(() => {
        this.setState({ isVisible: true })
      }, 2)
    })
  }

  onChangeFilter = (e) => {
    this.setState({ filter: e.target.value })
  }

  getSelectedMembers = () => {
    const { filter } = this.state
    if (!filter) {
      return this.state.selectedMembers
    }

    return this.state.selectedMembers.filter((member) => {
      const lastName = member.lastName.toLowerCase()
      const firstName = member.firstName.toLowerCase()
      const search = filter.toLowerCase()
      return lastName.includes(search) || firstName.includes(search)
    })
  }

  render() {
    const { record, classes } = this.props
    const members = this.state.selectedMembers

    const selectableMembers = this.state.members
      .filter((member) => {
        for (let i = 0; i < members.length; i++) {
          if (member.personId === members[i].personId) {
            members[i] = member
            return false
          }
        }
        return true
      })
      .sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1
        }
        if (a.lastName > b.lastName) {
          return 1
        }
        return 0
      })

    return (
      <div>
        <br />
        <br />
        <div>
          <h3>Assigned Members</h3>
        </div>
        <TextField
          variant='standard'
          label='Filter members'
          onChange={this.onChangeFilter}
          value={this.state.filter}
        />
        <br />
        <div className={classes.chips}>
          {this.getSelectedMembers().map((item) => (
            <Chip
              icon={<PersonIcon />}
              key={item.personId}
              label={item.firstName + ' ' + item.lastName}
              className={classes.chip}
              onDelete={() => this.onDelete(record, item)}
            />
          ))}
        </div>
        <br />
        {this.state.isVisible && (
          <Autocomplete
            options={selectableMembers}
            getOptionLabel={(member) =>
              `${member.firstName} ${member.lastName}`
            }
            clearOnEscape={true}
            onChange={(e, value) => this.onAdd(record, value)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant='standard'
                  label='Select member to add:'
                  fullWidth
                />
              )
            }}
          />
        )}
        <br />
      </div>
    )
  }
}

MemberArray.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(styles)(MemberArray)
