import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { addMember, removeMember } from '../../roomGroups/roomGroupService'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import useRefresh from '../../common/useRefresh'

const getInitialValue = (record, source) => {
  const value = record && source ? record[source] : []
  return value.map((g) => ({ ...g, id: g.id || g.group_meeting_room_id }))
}

const RoomGroupSelect = ({
  source,
  record,
  choices,
  onSelect,
  selectClassName,
  refreshOnChange = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const isListLoading = useSelector((state) => state.admin.loading)
  const [initialValue, setInitialValue] = useState(
    getInitialValue(record, source),
  )

  const refresh = useRefresh()

  useEffect(() => {
    if (refreshOnChange) {
      const initValue = getInitialValue(record, source)
      const hasChanged = initValue.length !== initialValue.length
      if (hasChanged) setInitialValue(initValue)
    }
  }, [initialValue.length, record, refreshOnChange, source])

  const handleValueChange = (groups, { action, option, removedValue }) => {
    if (!['select-option', 'remove-value'].includes(action)) {
      return
    }

    const group = option || removedValue
    const groupId = group.id
    const isSelected = action === 'remove-value'
    const newValue = isSelected
      ? initialValue.filter((g) => g.id !== groupId)
      : [...initialValue, group]

    if (onSelect) {
      setInitialValue(newValue)
      onSelect(groupId, action)
      return
    }

    // element in row in user list
    if (!isSelected) {
      setIsLoading(true)
      addMember(groupId, record.personId).then(() => {
        setIsLoading(false)
        refresh()
      })
    } else {
      setIsLoading(true)
      removeMember(group.id, record.personId).then(() => {
        setIsLoading(false)
        refresh()
      })
    }
  }

  if (isLoading || isListLoading) return <CircularProgress />
  return (
    <Select
      isMulti
      className={selectClassName}
      value={initialValue}
      onChange={handleValueChange}
      options={choices}
      menuPortalTarget={document.body}
      closeMenuOnScroll
      getOptionLabel={(g) => g.name}
      getOptionValue={(g) => g.id}
      isClearable={false}
    />
  )
}

RoomGroupSelect.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  refreshOnChange: PropTypes.bool,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onSelect: PropTypes.func,
  selectClassName: PropTypes.string,
}

export default RoomGroupSelect
