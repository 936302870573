import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { showNotification } from 'react-admin'
import { connect } from 'react-redux'
import {
  Switch,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core'
import dataProvider from '../../dataProvider'
import { PUT_UPDATE } from '../../common/requestTypes'
import { loadPersonId } from '../../service/TokenService'

const UserStatusSwitch = ({ record, source, showNotification }) => {
  const [currentStatus, setCurrentStatus] = useState(record[source])
  const [newStatus, setNewStatus] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const handleChangeStatus = () => {
    setOpenDialog(true)
    setNewStatus(!currentStatus)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleValueChangeConfirm = () => {
    const endpoint = newStatus ? 'enable' : 'disable'

    dataProvider(PUT_UPDATE, `person/${record.personId}/${endpoint}`)
      .then(() => {
        setCurrentStatus(newStatus)
        setOpenDialog(false)
        showNotification('User status has been changed')
      })
      .catch((err) => {
        if (err) {
          showNotification('User status could not be changed', 'error')
        }
        setOpenDialog(false)
      })
  }

  const isUser = Number(loadPersonId()) === record.personId
  return (
    !isUser && (
      <>
        <Switch
          color='primary'
          checked={currentStatus}
          onChange={handleChangeStatus}
        />

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {'Are you sure you want to change the role of the user?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              Disagree
            </Button>
            <Button
              onClick={handleValueChangeConfirm}
              color='primary'
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

UserStatusSwitch.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  showNotification: PropTypes.func.isRequired,
}

export default connect(null, {
  showNotification,
})(UserStatusSwitch)
