import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { ReactComponent as LogoSvg } from './assets/Logo.svg'

const Logo = () => <LogoSvg />

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
  spacer: {
    flex: 1,
  },
  logoContainer: {
    width: 38,
    height: 34,
  },
}

const AreaAppBar = withStyles(styles)(({ classes, ...props }) => {
  return (
    <AppBar {...props}>
      <Typography
        variant='h5'
        color='inherit'
        className={classes.title}
        id='react-admin-title'
      />
      <div className={classes.logoContainer}>
        <Logo />
      </div>
      <span className={classes.spacer} />
    </AppBar>
  )
})

export default AreaAppBar
