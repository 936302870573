import cloneDeep from 'lodash/cloneDeep'

export const communityEventGetOne = (dataOriginal) => {
  const data = cloneDeep(dataOriginal)
  data.tags = data.tags && data.tags.map((t) => t.tagId)
  return { data }
}

export const createCommunityEvent = (record) => {
  const data = cloneDeep(record)

  const form = new window.FormData()
  form.append('name', data.name)
  form.append('location', data.location)
  form.append('details', data.details)
  form.append('startsAt', data.startsAt)
  form.append('endsAt', data.endsAt)
  data.image && form.append('image', data.image.rawFile)
  data.communityId && form.append('communityId', data.communityId)

  /* The TagInput component writes the tags into window.tags
   the endpoint expects whole tag objects (not only their ids)
   so we need to convert the tag is from the reference input
   back to full objects */
  if (Array.isArray(data.tags)) {
    data.tags = data.tags.map((id) => window.tags.find((t) => t.tagId === id))
    form.append('tags', JSON.stringify(data.tags))
  }

  return form
}
