import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import AdminPage from './pages/AdminPage'
import authProvider from './authProvider'
import { AUTH_LOGIN } from 'ra-core'
import {
  defaultCookieOptions,
  loadRole,
  loadToken,
  logout,
  SESSION_TOKEN_KEY,
} from './service/TokenService'

const App = () => {
  useEffect(() => {
    const sessionToken = Cookies.get(SESSION_TOKEN_KEY)
    if (sessionToken) {
      authProvider(AUTH_LOGIN, { sessionToken })
        .then(() => {
          window.location.replace(loadRole() === 'staff' ? '/#/booking' : '/')
        })
        .catch(() => {
          logout()
        })
        .finally(() => {
          Cookies.remove(SESSION_TOKEN_KEY, defaultCookieOptions)
        })
    }
  })

  // in some magic circumstances that i can very rarely reproduce
  // above effect is not enough to redirect to login
  // make sure we get there if there is neither a url token
  // nor any local storage values
  const sessionToken = Cookies.get(SESSION_TOKEN_KEY)
  const token = loadToken()
  const role = loadRole()
  if (!sessionToken && (!token || !role)) {
    document.location.assign('/#/login')
  }

  return <AdminPage />
}

export default App
