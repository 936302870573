import { loadToken } from '../service/TokenService'
import dataProvider from '../dataProvider'
import { GET_LIST } from 'ra-core'

const getRoomUrl = (groupId, roomId) => {
  const apiUrl = process.env.REACT_APP_WEB_API_V2_URL
  const endPoint = `group-rooms/${groupId}/rooms/${roomId}`
  return `${apiUrl}/${endPoint}`
}

const getMemberUrl = (groupId, memberId) => {
  const apiUrl = process.env.REACT_APP_WEB_API_V2_URL
  const endPoint = `group-rooms/${groupId}/members/${memberId}`
  return `${apiUrl}/${endPoint}`
}

const getHeader = () => ({
  Authorization: loadToken(),
})

export const addRoom = (groupId, roomId) => {
  return window.fetch(getRoomUrl(groupId, roomId), {
    headers: getHeader(),
    method: 'POST',
  })
}

export const removeRoom = (groupId, roomId) => {
  return window.fetch(getRoomUrl(groupId, roomId), {
    headers: getHeader(),
    method: 'DELETE',
  })
}

export const addMember = (groupId, memberId) => {
  return window.fetch(getMemberUrl(groupId, memberId), {
    headers: getHeader(),
    method: 'POST',
  })
}

export const removeMember = (groupId, memberId) => {
  return window.fetch(getMemberUrl(groupId, memberId), {
    headers: getHeader(),
    method: 'DELETE',
  })
}

export const addMembers = (groupId, memberIds = []) => {
  return window.fetch(getMemberUrl(groupId, memberIds.join(',')), {
    headers: getHeader(),
    method: 'POST',
  })
}

export const removeMembers = (groupId, memberIds = []) => {
  return window.fetch(getMemberUrl(groupId, memberIds.join(',')), {
    headers: getHeader(),
    method: 'DELETE',
  })
}

export const getRooms = () => {
  return dataProvider(GET_LIST, 'meeting-rooms', {})
}

export const getMembers = () => {
  return dataProvider(GET_LIST, 'person/all', {})
}
