import { useEffect, useState } from 'react'
import { GET_LIST } from 'react-admin'
import dataProvider from '../../dataProvider'

const RoomGroupsProvider = ({ render, ...props }) => {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    dataProvider(GET_LIST, 'group-rooms')
      .then((response) => {
        setGroups(response.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return render(groups, props)
}

export default RoomGroupsProvider
