import React from 'react'
import PropTypes from 'prop-types'
import { DeleteWithConfirmButton } from 'react-admin'

const MessageDeleteButton = ({ record, ...props }) => {
  if (!record.isActive) {
    return null
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <DeleteWithConfirmButton
        {...props}
        record={{ id: '' }} // deleting the active record doesn't require the id
        confirmTitle={record.message}
        resource='area-tv-cms/active-message'
      />
    </div>
  )
}

MessageDeleteButton.propTypes = {
  record: PropTypes.any,
}

export default MessageDeleteButton
