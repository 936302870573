import React from 'react'
import CommunityUpdatesIcon from '@material-ui/icons/Message'
import CommunityUpdateEdit from './CommunityUpdateEdit'
import CommunityUpdateCreate from './CommunityUpdateCreate'

const edit = (props) => <CommunityUpdateEdit {...props} />
const create = (props) => <CommunityUpdateCreate {...props} />
const icon = () => <CommunityUpdatesIcon />

const CommunityUpdates = {
  create,
  edit,
  icon,
}

export default CommunityUpdates
