import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  input: {
    width: '300px',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
})

const AttachmentLinks = ({ links = [], onAdd, onDelete, classes }) => {
  const [linkText, setLinkText] = useState('')

  const getLinks = () =>
    links.map((link, i) =>
      typeof link === 'string' ? { url: link, id: i } : link,
    )

  const save = () => {
    onAdd(linkText)
    setLinkText('')
  }

  return (
    <>
      <Typography className={classes.header}>
        Youtube Link Attachments:
      </Typography>
      {getLinks().map((link, i) => (
        <div key={link.id}>
          <a href={link.url}>{link.url}</a>
          <Button
            className={classes.button}
            onClick={() => {
              onDelete(link.id, i)
            }}
          >
            Delete
          </Button>
        </div>
      ))}

      <div>
        <Typography variant='body2'>Add a Link Attachment: </Typography>
        <Input
          className={classes.input}
          onChange={(e) => setLinkText(e.target.value)}
          value={linkText}
        />
        <Button
          className={classes.button}
          color='primary'
          onClick={save}
          type='button'
        >
          Save
        </Button>
      </div>
      <br />
    </>
  )
}

AttachmentLinks.propTypes = {
  links: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(styles)(AttachmentLinks)
