import React from 'react'
import {
  TextInput,
  SimpleForm,
  Edit,
  Toolbar,
  SaveButton,
  required,
} from 'react-admin'
import { isValidDate, isDateInFuture } from '../common/validators'
import DateTimePicker from '../common/fields/DateTimePicker'
import MessageDeleteButton from './MessageDeleteButton'
import { toolbarStyles } from './styles'

const MessageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm
      variant='standard'
      redirect='list'
      margin='normal'
      toolbar={<MessageEditToolbar />}
    >
      <TextInput multiline source='message' validate={required()} fullWidth />
      <DateTimePicker
        source='expiry'
        validate={[required(), isValidDate, isDateInFuture]}
      />
    </SimpleForm>
  </Edit>
)

const MessageEditToolbar = (props) => (
  <Toolbar {...props} classes={toolbarStyles()}>
    <SaveButton {...props} />
    <MessageDeleteButton {...props} />
  </Toolbar>
)

export default MessageEdit
