import React from 'react'
import PropTypes from 'prop-types'
import { Chip, withStyles } from '@material-ui/core'

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0.25),
  },
})

const VISIBLE_ROOMS_COUNT = 4

const ChipArray = ({ classes, record, source }) => {
  const data = record[source] || []

  const smallerData = data.slice(0, VISIBLE_ROOMS_COUNT)

  return (
    <div>
      {smallerData.map((item) => (
        <Chip
          size='small'
          key={item.name}
          label={item.name}
          className={classes.chip}
        />
      ))}
      {data.length > VISIBLE_ROOMS_COUNT && (
        <Chip size='small' label={'...'} className={classes.chip} />
      )}
    </div>
  )
}

ChipArray.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(ChipArray)
