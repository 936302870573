import React from 'react'
import CommunityList from './CommunityList'
import CommunityIcon from '@material-ui/icons/Group'
import CommunityEdit from './CommunityEdit'
import CommunityCreate from './CommunityCreate'

const list = (props) => <CommunityList {...props} />
const edit = (props) => <CommunityEdit {...props} />
const create = (props) => <CommunityCreate {...props} />
const icon = () => <CommunityIcon />

const Community = {
  create,
  list,
  edit,
  icon,
}

export default Community
