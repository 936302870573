import React from 'react'
import PropTypes from 'prop-types'
import {
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import { Link } from 'react-router-dom'
import omit from 'lodash/omit'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDelete, useNotify } from 'react-admin'

const useStyles = makeStyles({
  button: {
    float: 'right',
  },
})

const ActionButton = ({ record }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const notify = useNotify()

  const [deleteOne] = useDelete('meeting-rooms', record.id, null, {
    refresh: true,
    onSuccess: () => {
      notify(`Room ${record.name} deleted`, 'info')
    },
    onFailure: () => {
      notify(`Error: Room ${record.name} could not be deleted`, 'warning')
    },
  })

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event?.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleDelete = (event) => {
    event.stopPropagation()
    deleteOne()
  }

  return (
    <div>
      <IconButton
        className={classes.button}
        aria-label='more'
        aria-controls='action-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='action-menu'
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={{
            pathname: '/meeting-rooms/create',
            state: { record: omit(record, 'id', 'roomId', 'imageUrl') },
          }}
        >
          <ListItemIcon>
            <LibraryAddIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Clone</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={(e) => e.stopPropagation()}
          to={{
            pathname: `/bookings`,
            search: `?roomId=${record.id}`,
          }}
        >
          <ListItemIcon>
            <CalendarTodayIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Show Bookings</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Remove Room</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}
ActionButton.propTypes = {
  record: PropTypes.object,
}

export default ActionButton
