import React from 'react'
import MessageIcon from '@material-ui/icons/Message'
import MessageList from './MessageList'
import MessageCreate from './MessageCreate'
import MessageEdit from './MessageEdit'

const list = (props) => <MessageList {...props} />
const icon = () => <MessageIcon />
const create = (props) => <MessageCreate {...props} />
const edit = (props) => <MessageEdit {...props} />

const Messages = {
  list,
  icon,
  create,
  edit,
}

export default Messages
