import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import { useUpdate, useRedirect, useNotify, SaveButton } from 'react-admin'
import { patchPolicy } from './policyService'

const RoomGroupSaveButton = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [update] = useUpdate('group-rooms')
  const formState = useFormState()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath } = props

  const handleClick = useCallback(() => {
    const { values, valid } = formState
    if (!valid) {
      return
    }

    setLoading(true)
    update(
      { payload: { data: values, id: values.id } },
      {
        onSuccess: () => {
          const onFinished = () => {
            notify('ra.notification.updated', 'info', { smart_count: 1 })
            redirectTo(basePath)
          }
          patchPolicy(values.id, values.bookingPolicies).then(onFinished)
        },
        onFailure: () => {
          notify('Could not update room-groups', 'warning')
          setLoading(false)
        },
      },
    )
  }, [formState, update, redirectTo, notify, basePath])

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      disabled={isLoading}
    />
  )
}

RoomGroupSaveButton.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export default RoomGroupSaveButton
