import React from 'react'
import UserList from './UsersList'
import UsersIcon from '@material-ui/icons/Person'

const list = (props) => <UserList {...props} />
const icon = () => <UsersIcon />

const Users = {
  list,
  icon,
}

export default Users
