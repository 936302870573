import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { addField, Labeled } from 'react-admin'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  withStyles,
} from '@material-ui/core'
import AVAILABILITY_DEFAULT from './AvailabilityDefault'

const styles = () => ({
  table: {
    maxWidth: '30rem',
  },
  input: {
    width: '2.5rem',
  },
  divider: {
    margin: '0 1rem',
  },
})

const AvailabilityInput = ({ classes, record, source, input }) => {
  const [data, setData] = useState(record[source] || { en: [] })
  const { onChange } = input

  useEffect(() => {
    if (record[source] == null) {
      setData(AVAILABILITY_DEFAULT)
      onChange(AVAILABILITY_DEFAULT)
    }
  }, [record, source, onChange])

  const getHours = (day) => {
    return (day.hours || '').split(' - ')
  }

  const updateAvailability = (
    values,
    newValue,
    i,
    isFirstValue,
    isReversed,
  ) => {
    const vals = values.map((entry, index) => {
      if (index === i) {
        const hours = getHours(entry)
        let newHours
        if (isReversed) {
          newHours = isFirstValue ? [hours[0], newValue] : [newValue, hours[1]]
        } else {
          newHours = isFirstValue ? [newValue, hours[1]] : [hours[0], newValue]
        }

        return { ...entry, hours: `${newHours[0]} - ${newHours[1]}` }
      }

      return entry
    })

    return vals
  }

  const handleChange = (e, i, isFirstVal) => {
    const value = e.target.value
    const update = {
      en: updateAvailability(data.en || [], value, i, isFirstVal),
      ar: updateAvailability(data.ar || [], value, i, isFirstVal, true),
    }

    setData(update)
    onChange(update)
  }

  return (
    <Labeled label='Availability'>
      <Table className={classes.table}>
        <TableBody>
          {data.en.map((entry, i) => (
            <TableRow key={entry.day}>
              <TableCell>{entry.day}</TableCell>
              <TableCell>
                <TextField
                  defaultValue={getHours(entry)[0]}
                  className={classes.input}
                  onChange={(e) => handleChange(e, i, true)}
                />
                <span className={classes.divider}>&ndash;</span>
                <TextField
                  defaultValue={getHours(entry)[1]}
                  className={classes.input}
                  onChange={(e) => handleChange(e, i)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Labeled>
  )
}

AvailabilityInput.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(addField(AvailabilityInput))
