import { useDispatch, useSelector } from 'react-redux'

function useRefresh() {
  const dispatch = useDispatch()

  const viewVersion = useSelector((state) => {
    return state.admin.ui.viewVersion
  })

  const refresh = () => {
    dispatch({
      type: 'RA/REFRESH_VIEW',
      admin: { ui: { viewVersion: viewVersion + 1 } },
    })
  }

  return refresh
}

export default useRefresh
