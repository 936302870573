import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chip, withStyles } from '@material-ui/core'
import MeetingRoomIcon from '@material-ui/icons/EventSeat'
import { addRoom, getRooms, removeRoom } from '../roomGroupService'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  chips: {
    marginTop: theme.spacing(2),
  },
})

class RoomArray extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: true,
      rooms: [],
      selectedRooms: props.record.meetingRooms || [],
      filter: '',
    }
  }

  componentDidMount() {
    getRooms().then((response) => {
      this.setState({
        rooms: response.data,
      })
    })
  }

  onChange = (value) => {
    this.setState({ value })
  }

  onDelete = (group, room) => {
    removeRoom(group.id, room.meetingRoomId).then(() => {
      this.setState((state) => ({
        selectedRooms: state.selectedRooms.filter(
          (r) => r.meetingRoomId !== room.meetingRoomId,
        ),
      }))
    })
  }

  onAdd = (group, room) => {
    if (!room) {
      return
    }

    addRoom(group.id, room.meetingRoomId).then(() => {
      this.setState((state) => ({
        selectedRooms: [...state.selectedRooms, room],
        isVisible: false,
      }))
      window.setTimeout(() => {
        this.setState({ isVisible: true })
      }, 2)
    })
  }

  onChangeFilter = (e) => {
    this.setState({ filter: e.target.value })
  }

  getSelectedRooms = () => {
    const { filter } = this.state
    if (!filter) {
      return this.state.selectedRooms
    }

    return this.state.selectedRooms.filter((room) => {
      const search = filter.toLowerCase()
      return room.name.toLowerCase().includes(search)
    })
  }

  render() {
    const { record, classes } = this.props
    const rooms = this.state.selectedRooms

    const selectableRooms = this.state.rooms
      .filter((room) => {
        for (let i = 0; i < rooms.length; i++) {
          if (room.meetingRoomId === rooms[i].meetingRoomId) {
            return false
          }
        }
        return true
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

    return (
      <div>
        <br />
        <br />
        <div>
          <h3>Assigned Rooms</h3>
        </div>
        <TextField
          variant='standard'
          label='Filter rooms'
          onChange={this.onChangeFilter}
          value={this.state.filter}
        />
        <div className={classes.chips}>
          {this.getSelectedRooms().map((item) => (
            <Chip
              icon={<MeetingRoomIcon />}
              key={item.name}
              label={item.name}
              className={classes.chip}
              onDelete={() => this.onDelete(record, item)}
            />
          ))}
        </div>
        <br />
        {this.state.isVisible && (
          <Autocomplete
            options={selectableRooms}
            getOptionLabel={(room) => room.name}
            onChange={(e, value) => this.onAdd(record, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Select room to add:'
                fullWidth
              />
            )}
          />
        )}
        <br />
      </div>
    )
  }
}

RoomArray.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(styles)(RoomArray)
