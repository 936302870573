import { makeStyles } from '@material-ui/core/styles'

const toolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export { toolbarStyles }
