import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fb5f26',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: 'Avenir-Book',
    textAlign: 'justify',

    subtitle2: {
      fontFamily: 'Avenir-Book',
    },
    body1: {
      fontFamily: 'Avenir-Book',
    },
    body2: {
      fontFamily: 'Avenir-Book',
    },
    h6: {
      fontFamily: 'Avenir-Book',
    },
    caption: {
      fontFamily: 'Avenir-Book',
    },
    button: {
      fontFamily: 'Avenir-Book',
    },
  },
  overrides: {
    a: {
      color: '#fb5f26',
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['PT Sans'],
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '8px 6px',
        fontSize: '13px',
      },
    },
  },
})

export default theme
